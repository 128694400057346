import React from "react";
import Text from "./Text";
import "./TextBlock.scss";

export default ({ children }) => {
  const blockClass = children.length > 1 ? "TextBlock-double" : "TextBlock-single"
  return <section className="TextBlock">
    {children.map(block => <div key={block.id} className={`TextBlock-wrapper ${blockClass}`}>
      <h2 className="TextBlock-header">{block.heading}</h2>
      <div className="TextBlock-text"><Text text={block.text} /></div>
    </div>)}
  </section>
}
