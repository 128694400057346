import React from "react";

export default function useScrollTop() {
  const [offset, set] = React.useState(0);
  const lastOffset = React.useRef(offset);

  React.useLayoutEffect(() => {
    let raf;

    const onFrame = () => {
      const newOffset = Math.round(window.pageYOffset);
      if (newOffset !== lastOffset.current) {
        lastOffset.current = newOffset;
        set(Math.max(newOffset, 0));
      }
      loop();
    };

    const loop = () => {
      raf = requestAnimationFrame(onFrame);
    };

    loop();

    return () => {
      cancelAnimationFrame(raf);
    };
  }, []);

  return offset;
}
