import React from "react";
import Comparison from "components/Comparison";
import defaultSrc from "data/topchallenges.csv";

const defaultColumns = [
  "Affordability and quality of housing options",
  "Homelessness",
  "Concerns with safety, clean streets, and crime",
  "Cost and burden of taxes & regulation"
]
const defaultContent = {
  ratio: "44/50",
  ratioLabel: "cities identified housing or homelessness among their top three greatest challenges."
}

export default ({ src=defaultSrc, columns=defaultColumns, content=defaultContent }) => <Comparison src={src} columns={columns} content={content} />