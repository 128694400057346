import { csvParse } from "d3-dsv";

export const storeData = (endpoint,data) => ({
  type: "STORE_DATA",
  endpoint,
  data
})
export const requestData = (endpoint) => ({
  type: "REQUEST_DATA",
  endpoint
})

export const fetchCsv = endpoint => {
  return (dispatch) => {
    return fetch(endpoint)
      .then(res=>res.text())
      .then(res=> {
        dispatch(storeData(endpoint,csvParse(res)))
      })
  }
}
