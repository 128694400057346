import React from "react";
import Tooltip from "react-tooltip";

export default ({ explainer, id = "cityIndex" }) => {
  return (
    <aside className="HowDidWeButton">
      <button
        data-tip={explainer}
        data-for={`${id}-tooltip-HowDidWeButton`}
        className={"CityIndexScroll-HowDidWeButton"}
      >
        ?
      </button>
      <Tooltip
        id={`${id}-tooltip-HowDidWeButton`}
        className={"HowDidWeButton-tooltip"}
        multiline={true}
        type={"light"}
        place={"left"}
      />
    </aside>
  );
};

export const HowDidWe = ({ text, place = "left" }) => {
  return (
    <button
      data-tip={text.replace(/\n/g, "<br />")}
      data-for={`HowDidWe--${place}`}
      className={"HowDidWe"}
    >
      ?
    </button>
  );
};

export const HowDidWeTooltip = ({ place }) => {
  return (
    <Tooltip
      id={`HowDidWe--${place}`}
      className={"HowDidWeButton-tooltip"}
      multiline={true}
      type={"light"}
      place={place}
      effect={"solid"}
    />
  );
};
