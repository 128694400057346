import React, { useEffect, useRef } from "react";
import Builder from "../components/Builder";
import "./KeyFinding.scss";
import { keyFinding as query } from "queries";
import { useApi } from "Fetch";
import { KeyFindingLinks, NextFinding } from "./KeyFindingsNav";
import { GlobalHeader } from "../components/GlobalHeader";
import GrowIn from "GrowIn";
import Missing from "components/Missing";
// import Seo from "seo";

const Header = ({ image, navTitle, heading }) => {
  return (
    <GrowIn reverse={true}>
      <div className={"KeyFinding-header"}>
        {image[0] && (
          <img
            src={image[0].url}
            className={"KeyFinding-header-image"}
            alt={"Abstract drawing representing " + navTitle}
          />
        )}
        <h1 className={"KeyFinding-header-heading"}>{heading}</h1>
      </div>
    </GrowIn>
  );
};

const KeyFinding = ({
  navTitle,
  slug,
  heading,
  image,
  children,
  forwardRef
}) => {
  return (
    <section id={slug} className={"KeyFinding"} ref={forwardRef}>
      <Header image={image} heading={heading} navTitle={navTitle} />
      <div className={"KeyFinding-body"}>
        <Builder blocks={children} />
      </div>
    </section>
  );
};

const KeyFindingWithBounds = ({ id, addItem, ...rest }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      addItem(ref.current);
    }
  }, [addItem]);

  return <KeyFinding forwardRef={ref} id={id} {...rest} />;
};
export default KeyFindingWithBounds;

export const KeyFindingPage = ({ match }) => {
  const variables = { slug: match.params.slug };
  const res = useApi(query, variables);
  const entry = res && res.data.entries[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.slug]);

  if (!res) {
    return null;
  }

  if (!entry) {
    return <Missing />;
  }

  return (
    <div className="KeyFinding" style={{ paddingBottom: "15em" }}>
      {/* <Seo title={`${entry.navTitle} | Future of Work and Cities`} description={entry.heading} image={entry.seoImage[0]} /> */}
      <GlobalHeader />
      <KeyFinding {...entry} children={entry.keyFindingBuilder} />
      <NextFinding current={match.params.slug} />
      <KeyFindingLinks current={match.params.slug} hash={false} />
    </div>
  );
};
