import React, { useState } from "react";
import "./ShowMore.scss";

const ShowMore = ({ children, count }) => {
  const [show, setShow] = useState(false);

  if (show) {
    return children;
  } else {
    return (
      <div className="ShowMore">
        <button className="ShowMoreButton" onClick={() => setShow(true)}>
          Show {count && `${count} `}More
        </button>
      </div>
    );
  }
};

export default ShowMore;
