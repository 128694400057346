import React, { useEffect, useState, useReducer, useRef } from "react";
import Grid from "components/Grid";
import { indexcolors as colors } from "../constants";
import CityIndexScrollerMobile from "./CityIndexScrollerMobile";

import useBreakpoint from "components/useBreakpoint";
import { HowDidWe } from "components/HowDidWeButton";
import { useIndex } from "../Fetch";
import defaultItems from "dummy/CityIndexScroller.js";
import { Waypoint } from "react-waypoint";
import Tooltip from "react-tooltip";

const themeColors = {
  green: "#005056",
  blue: "#3FA7C3",
  yellow: "#F7AF43"
};

export default ({ cityIndexScroller = defaultItems, explainer }) => {
  const items = cityIndexScroller;
  const data = useIndex();
  const breakpoint = useBreakpoint();

  return data ? (
    breakpoint === "desktop" ? (
      <CityIndexScroller
        data={data}
        items={items}
        explainer={explainer}
        reverseScale
      />
    ) : (
      <CityIndexScrollerMobile
        data={data}
        items={items}
        explainer={explainer}
      />
    )
  ) : null;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        measurements: {
          ...state.measurements,
          [action.key]: action.value
        }
      };
    default:
      throw new Error();
  }
};
const CityIndexScroller = ({ data, items, reverseScale }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="CityIndexScroller">
      <div className="CityIndexScroller-items">
        {items.map((item, i) => (
          <Item
            {...item}
            isLoaded={isLoaded}
            dispatch={dispatch}
            key={item.cityIndexColumn}
          />
        ))}
      </div>
      <CitySwitcher
        data={data}
        items={items}
        measurements={state.measurements}
        reverseScale={reverseScale}
        onSetActive={setIsLoaded}
      />
    </div>
  );
};

const CitySwitcher = ({
  data,
  items,
  measurements,
  reverseScale,
  onSetActive
}) => {
  const initial = items[0].cityIndexColumn;
  const triggerHook = 0.5;
  const [active, setActive] = useState(false);

  const [index, setIndex] = useState(initial);

  useEffect(() => {
    if (measurements && active) {
      const onScroll = () => {
        const scrollY = window.scrollY + window.innerHeight * triggerHook;
        const currentIndex = Object.keys(measurements).reduce((r, key) => {
          if (!measurements[key]) return r;
          const { top, bottom } = measurements[key];
          if (scrollY >= top && scrollY < bottom) {
            return key;
          }
          return r;
        }, null);

        if (currentIndex) {
          setIndex(currentIndex);
        }
      };
      onScroll();
      window.addEventListener("scroll", onScroll);
      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    } else if (initial) {
      setIndex(initial);
    }
  }, [active, measurements, initial]);

  return (
    <Waypoint onEnter={() => setActive(true)} onLeave={() => setActive(false)}>
      <div className="CityIndexScroller-tiles">
        <Grid
          onSetActive={onSetActive}
          reverseScale={reverseScale}
          data={data}
          currentIndex={index}
          zoomIndex={initial}
        />
      </div>
    </Waypoint>
  );
};

const Item = ({
  cityIndexColumn: col,
  dispatch,
  isLoaded = false,
  ...rest
}) => {
  const nodeRef = useRef();

  useEffect(() => {
    const onResize = () => {
      const measurement = nodeRef.current.getBoundingClientRect();
      const top = window.scrollY + measurement.top;
      const bottom = top + measurement.height;
      dispatch({
        type: "update",
        key: col,
        value: {
          top,
          bottom
        }
      });
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [col, dispatch, isLoaded]);

  return <ItemContent forwardRef={nodeRef} {...rest} />;
};

export const ItemContent = ({
  ratio,
  ratioLabel,
  text,
  colorTheme,
  forwardRef,
  color = colors[5],
  tooltip
}) => {
  const breakpoint = useBreakpoint();

  useEffect(() => {
    Tooltip.rebuild();
  }, [breakpoint]);

  return (
    <div ref={forwardRef} className="CityIndexScrollerTrigger">
      {tooltip &&
        (breakpoint === "desktop" ? (
          <HowDidWe text={tooltip} place={"right"} key="right" />
        ) : (
          <HowDidWe text={tooltip} place={"top"} key="top" />
        ))}
      <div
        className={"CityIndexScrollerTrigger-ratio"}
        style={{ color: colorTheme ? themeColors[colorTheme] : color }}
      >
        {ratio}
      </div>
      <div className={"CityIndexScrollerTrigger-ratiolabel"}>{ratioLabel}</div>
      <div
        className={"CityIndexScrollerTrigger-text"}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};
