import React, { useEffect } from "react";
import { useApi } from "Fetch";
import { allKeyFindings as query } from "queries";
import KeyFinding from "blocks/KeyFinding";
import useScrollTrigger from "hooks/useScrollTrigger";
import { useDispatch } from "react-redux";

export default () => {
  const res = useApi(query);

  if (!res) {
    return null;
  }

  return <AllKeyFindings items={res.data.entries} />;
};

const AllKeyFindings = ({ items }) => {
  const { currentItem, addItem } = useScrollTrigger();
  const dispatch = useDispatch();

  useEffect(() => {
    const setLocalTitle = value => {
      dispatch({
        type: "SET_SUBTITLE",
        value
      });
    };
    const entry = currentItem ? items.find(d => d.slug === currentItem) : null;
    const localTitle = entry ? entry.navTitle : null;
    setLocalTitle(localTitle);
  }, [items, currentItem, dispatch]);

  return items.map(entry => (
    <KeyFinding
      key={entry.id}
      {...entry}
      children={entry.keyFindingBuilder}
      addItem={addItem}
    />
  ));
};
