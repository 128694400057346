import React from "react";
import { useSpring, a, config } from "react-spring";

import remap from "../utils/remap";

import { stepProgress } from "../utils/step";

const WIDTH = 2163;
const STAGE_WIDTH = 1440;
const STAGE_HEIGHT = 649;

const stageOffset = (WIDTH - STAGE_WIDTH) * -1;

const rightOffset = 679;
const targetRightOffset = 930;
const finalRightOffset = 1450;
const rightOpacity = 1;

const leftOffset = 0;
const targetLeftOffset = 251;
const finalLeftOffset = 710;

export default function CityBackround({ step, progress }) {
  const [
    { aStageOffset, aRightOffset, aLeftOffset, aRightOpacity },
    set
  ] = useSpring(() => ({
    aStageOffset: stageOffset,
    aRightOffset: rightOffset,
    aLeftOffset: leftOffset,
    aRightOpacity: rightOpacity,
    config: config.default
  }));

  const step1Progress = stepProgress(1, step, progress);
  const step2Progress = stepProgress(2, step, progress);
  const step3Progress = stepProgress(3, step, progress);
  const step5Progress = stepProgress(5, step, progress);

  const slideProgress = step2Progress;
  const finalSlideProgress = step3Progress;
  const opacityProgres = step2Progress + step3Progress;
  React.useLayoutEffect(() => {
    const ro = finalSlideProgress
      ? remap(
          finalSlideProgress,
          [0.1, 1],
          [targetRightOffset, finalRightOffset]
        )
      : remap(slideProgress, [0.1, 1], [rightOffset, targetRightOffset]);

    const lo = finalSlideProgress
      ? -1 *
        remap(finalSlideProgress, [0.1, 1], [targetLeftOffset, finalLeftOffset])
      : -1 * remap(slideProgress, [0.1, 1], [leftOffset, targetLeftOffset]);

    set({
      aStageOffset: remap(step1Progress, [0.1, 1], [stageOffset, 0]),
      aRightOffset: ro,
      aRightOpacity: 1 - remap(opacityProgres, [0.4, 1], [0, 0.6]),
      aLeftOffset: lo
    });
  }, [set, step1Progress, opacityProgres, slideProgress, finalSlideProgress]);

  // prettier-ignore
  return (
    <a.svg style={{opacity: step5Progress ? 0 : 1}} className="city-container" viewBox={`0 0 ${STAGE_WIDTH} ${STAGE_HEIGHT}`} preserveAspectRatio="xMidYMid meet">
      <a.g id="city" transform={aStageOffset.interpolate(x => `translate(${x}, 0)`)}>
        <a.g id="right" opacity={aRightOpacity} transform={aRightOffset.interpolate(x => `translate(${x}, 0)`)}>
            <path d="M1484,0 C1359.236,59.8312548 1240.554,168.05176 1141.744,309.871215 C973.92,550.746371 834.944,629.148256 764,645.493692 L764,649 L1484,649 L1484,0 Z" id="w3" fill="#25BDA3"></path>
            <path d="M1484,281 C1396.549,308.888097 1302.464,358.415319 1207.112,441.665011 C1036.318,590.782161 844.664,637.759843 764,647.108824 L764,649 L1484,649 L1484,281 Z" id="w2" fill="#008A77"></path>
            <path d="M1484,502 C1395.42903,509.690528 1309.10167,531.04966 1203.12865,571.577063 C1023.07719,642.570328 844.551475,648.557411 764.122119,648.971762 C753.158979,649.028811 1484,648.981771 1484,648.981771 L1484,502 Z" id="w1" fill="#005056"></path>
            <polygon id="13" fill="#25BDA3" points="522 649 522 345.67945 583.998495 303 646 345.67945 646 458.579 764 541.114288 764 649"></polygon>
            <polygon id="12" fill="#008A77" points="468 649 522 649 522 346 468 346"></polygon>
            <polygon id="10" fill="#25BDA3" points="263 649 425 649 425 273 263 273"></polygon>
            <polygon id="8" fill="#92D5E0" points="87 649 217 649 217 129 87 129"></polygon>
            <polygon id="9" fill="#25BDA3" points="245.537583 166 201.199016 209.673458 201 209.673458 201 345.960718 201 649 288 649 288 345.960718 288 209.673458"></polygon>
            <polygon id="11" fill="#92D5E0" points="383 649 468 649 468 385 383 385"></polygon>
            <polygon id="7" fill="#005056" points="0 649 87 649 87 243 0 243"></polygon>
        </a.g>
        <a.g id="left" transform={aLeftOffset.interpolate(x => `translate(${x}, 187.0)`)}>
            <polygon id="1" fill="#92D5E0" points="0 462 131 462 131 0 0 0"></polygon>
            <polygon id="2" fill="#25BDA3" points="304.591866 52 304.591866 132.814252 131 132.814252 131 462 304.591866 462 315.975872 462 421 462 421 52"></polygon>
            <polygon id="3" fill="#005056" points="278 462 395 462 395 243 278 243"></polygon>
            <polygon id="4" fill="#92D5E0" points="392 158.678696 392 462 540 462 540 158.678696 465.997996 116"></polygon>
            <polygon id="5" fill="#25BDA3" points="533 461 620 461 620 17 533 17"></polygon>
            <polygon id="6" fill="#25BDA3" points="533 23.6291677 533 462 707 462 704.290507 88.7845326 617.291008 89.4137002 616.808852 23"></polygon>
        </a.g>
      </a.g>
    </a.svg>
  );
}
