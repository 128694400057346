import React from "react";
import { useSpring, a, config } from "react-spring";
import { Waypoint } from "react-waypoint";

const imageOut = {
  transform: `scale(0.9)`,
  opacity: 0
};
const imageIn = {
  transform: `scale(1)`,
  opacity: 1
};

const GrowIn = props => {
  const [imageProps, setImageProps] = useSpring(() => ({
    from: imageOut,
    config: config.stiff
  }));

  return (
    <Waypoint
      onEnter={() => {
        setImageProps(imageIn);
      }}
      onLeave={() => {
        props.reverse ? setImageProps(imageOut) : setImageProps({});
      }}
    >
      <a.div style={imageProps}>{props.children}</a.div>
    </Waypoint>
  );
};

export default GrowIn;
