import React, { useState } from "react";
import { useCsv } from "Fetch";
import src from "data/CityMoves.csv";
import coords from "data/coords.json";
import { keySet } from "helpers";
import geography from "data/nation-10m.json";
import { scaleLinear } from "d3-scale";
import { useSpring, a } from "react-spring";
import { greens } from "../constants";
import keys from "keys";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography
} from "react-simple-maps";
import { percentage } from "format";
import Tooltip from "react-tooltip";
import "./MovingMap.scss";
import { useIndex } from "Fetch";
import AsyncComp from "AsyncComp";
import useBreakpoint from "./useBreakpoint";

const markerRadius = 5;

const allMarkers = Object.values(coords).reduce((r, d) => {
  r[d.region] = {
    region: d.region,
    coordinates: [d.coords.lng, d.coords.lat]
  };
  return r;
}, {});

const fill = {
  from: "#005D4C",
  to: greens[5],
  land: "#fff",
  water: "#C5E7EC"
};

const MovingMap = ({ indexData }) => {
  const data = useCsv(src);
  if (!data) {
    return null;
  }

  const allOrigins = keySet(data, "region");
  const fromTo = data.reduce((r, d) => {
    if (!r[d["region"]]) {
      r[d["region"]] = {};
    }
    if (!r[d["region"]][d["cleaned_name"]] && d["rank"] <= 5) {
      r[d["region"]][d["cleaned_name"]] = {
        name: d["cleaned_name"],
        share: d["share"]
      };
    }
    return r;
  }, {});

  // Remove Canada...
  const canada = ["Vancouver", "Toronto"];
  const origins = allOrigins.filter(region => canada.indexOf(region) < 0);

  const fromMarkers = getMarkers(origins);
  return (
    <Map
      markers={fromMarkers}
      fromTo={fromTo}
      data={data}
      indexData={indexData}
    />
  );
};

const Map = ({ markers, fromTo, data }) => {
  const indexData = useIndex();

  const [currentMarker, setCurrentMarker] = useState();
  const projection = "albersUsa";

  const toMarkers = currentMarker
    ? getMarkers(Object.keys(fromTo[currentMarker]))
    : null;
  const Zoom = ZoomableGroup;

  const cMarker = markers.find(d => d.region === currentMarker);
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === "desktop";
  const zoom = isDesktop ? 1 : 1.2;

  return (
    <div className="MovingMap" key={indexData ? "a" : "b"}>
      <ComposableMap
        projection={projection}
        projectionConfig={{
          scale: 800
        }}
        height={450}
        style={{
          width: "100%",
          height: "100%",
          display: "block"
        }}
      >
        <Zoom zoom={zoom}>
          <rect
            x={0}
            y={0}
            width={800}
            height={isDesktop ? 450 : 475}
            fill={fill.water}
            onTouchStart={() => {
              setCurrentMarker(null);
              Tooltip.hide();
            }}
            onMouseEnter={() => setCurrentMarker(null)}
          />
          <Geographies geography={geography} disableOptimization>
            {(geographies, projection) => {
              let geos = geographies.map((geography, i) => {
                return (
                  <Geography
                    key={geography.properties.name}
                    geography={geography}
                    projection={projection}
                    style={{
                      default: {
                        fill: fill.land,
                        pointerEvents: "none"
                      }
                    }}
                  />
                );
              });
              return geos;
            }}
          </Geographies>

          <Geographies geography={markers} key={"geo" + currentMarker}>
            {(markers, projection) => {
              return markers.map(marker => {
                const coords = projection(marker.coordinates);
                if (!coords) {
                  return null;
                }
                const [x, y] = coords;
                const moving =
                  indexData &&
                  indexData.find(d => d[keys.label] === marker.region);
                const movingValue = moving && moving[keys.moving];
                return (
                  <circle
                    key={marker.region + "from" + currentMarker}
                    cx={x}
                    cy={y}
                    r={markerRadius}
                    data-tip={makeTooltip(
                      marker,
                      fromTo[marker.region],
                      movingValue
                    )}
                    data-for={"MovingMap"}
                    data-html
                    fill={currentMarker === marker.region ? "#fff" : fill.from}
                    onTouchStart={() => {
                      setCurrentMarker(marker.region);
                    }}
                    onMouseEnter={() => {
                      setCurrentMarker(marker.region);
                    }}
                  />
                );
                // else console.log('no coords ' + marker.region)
              });
            }}
          </Geographies>
          {toMarkers && (
            <Geographies geography={toMarkers}>
              {(markers, projection) => {
                const thisData = data.filter(
                  d => d["region"] === currentMarker
                );
                const scale = scaleLinear()
                  .range([2, 12])
                  .domain(extent(thisData, "share"));

                const currentMarkerCoords = projection(
                  allMarkers[currentMarker].coordinates
                );
                const theLines = markers.map(marker => {
                  const coords = projection(marker.coordinates);
                  if (!coords) {
                    console.log(`No coords - ${marker.region}`);
                    return null;
                  }
                  const thisDatum = thisData.find(
                    d => d["cleaned_name"] === marker.region
                  );
                  const value = parseFloat(thisDatum["share"]);
                  const r = scale(value);
                  const distance = getDistance(coords, currentMarkerCoords);
                  if (!distance || distance * 2 < r) {
                    console.log(
                      `No distance ${currentMarker} - ${marker.region}`
                    );
                    return null;
                  }
                  const angle = triangleSSS(distance, distance, r);
                  if (!angle) {
                    console.log(`No angle ${currentMarker} - ${marker.region}`);
                    return null;
                  }
                  const vectorAngle = getAngle(currentMarkerCoords, coords);
                  const [x0a, y0a] = findNewPoint(
                    currentMarkerCoords,
                    vectorAngle,
                    markerRadius
                  );

                  const paths = [
                    `
                  M${x0a} ${y0a}
                  L${x0a} ${y0a}
                `,
                    `
                  M${x0a} ${y0a}
                  L${coords[0]} ${coords[1]}
                `
                  ];

                  return (
                    <React.Fragment
                      key={currentMarker + marker.region + "line"}
                    >
                      <MorphPath paths={paths} />
                      <AnimatedCircle
                        fill={fill.to}
                        r={r}
                        cx={coords[0]}
                        cy={coords[1]}
                      />
                    </React.Fragment>
                  );
                });

                return theLines;
              }}
            </Geographies>
          )}
          <Geographies geography={cMarker ? [cMarker] : []}>
            {(markers, projection) => {
              return markers.map(marker => {
                const coords = projection(marker.coordinates);
                return (
                  <circle
                    key={marker.region}
                    style={{ pointerEvents: "none" }}
                    cx={coords[0]}
                    cy={coords[1]}
                    r={markerRadius}
                    stroke={fill.from}
                    fill={"#fff"}
                  />
                );
              });
            }}
          </Geographies>
        </Zoom>
      </ComposableMap>
      <Tooltip
        className="Tooltip MovingMapTooltip"
        id={"MovingMap"}
        style={{ cursor: "pointer" }}
        globalEventOff={!isDesktop ? "touchstart" : undefined}
      />
    </div>
  );
};

const AsyncMovingMap = props => (
  <AsyncComp>
    <MovingMap {...props} />
  </AsyncComp>
);

export default AsyncMovingMap;

const makeTooltip = (marker, toMarkers, movingValue) => {
  const { region } = marker;

  return `<div class="MovingMapTooltip-inner">
    <div class="MovingMapTooltip-inner-region">${region}</div>
    <div class="MovingMapTooltip-inner-total">
      <span class="value" style="color: ${greens[5]};">${percentage(
    movingValue
  )}</span> <span>thinking of moving to</span>
    </div>
    <ul class="MovingMapTooltip-inner-list">
      ${Object.values(toMarkers)
        .map(({ name, share }) => {
          return region !== name
            ? `<li class="MovingMapTooltip-inner-list-item">
            <span class="value" style="color: ${greens[5]};">${share}</span> <span>${name}</span>
          </li>`
            : null;
        })
        .join("")}
    </ul>
  </div>`;
};

const AnimatedCircle = ({ r, ...rest }) => {
  const props = useSpring({
    from: {
      r: 0
    },
    to: {
      r
    }
  });

  return (
    <>
      <a.circle
        style={{ pointerEvents: "none" }}
        r={props.r}
        opacity={0.5}
        {...rest}
      />
      <a.circle
        style={{ pointerEvents: "none" }}
        r={props.r.interpolate(r => r * 0.75)}
        opacity={1}
        {...rest}
      />
    </>
  );
};

const MorphPath = ({ paths }) => {
  const props = useSpring({
    from: {
      d: paths[0]
    },
    to: {
      d: paths[1]
    }
  });

  return (
    <a.path
      d={props.d}
      fill={fill.to}
      stroke={fill.to}
      className="pointer-none"
    />
  );
};

const findNewPoint = ([x, y], angle, distance) => {
  return [
    Math.round(Math.cos(angle) * distance + x),
    Math.round(Math.sin(angle) * distance + y)
  ];
};

const triangleSSS = (a, b, c) => {
  if (a + b <= c || b + c <= a || c + a <= b) throw new Error("No solution");
  const A = solveAngle(b, c, a);
  const B = solveAngle(c, a, b);
  const C = solveAngle(a, b, c);

  return [A, B, C];
};

// Returns angle C using law of cosines
function solveAngle(a, b, c) {
  var temp = (a * a + b * b - c * c) / (2 * a * b);
  if (-1 <= temp && temp <= 0.9999999) return Math.acos(temp);
  else if (temp <= 1)
    // Explained in https://www.nayuki.io/page/numerically-stable-law-of-cosines
    return Math.sqrt((c * c - (a - b) * (a - b)) / (a * b));
  else throw new Error("No solution");
}

const getDistance = ([x1, y1], [x2, y2]) => {
  var a = x1 - x2;
  var b = y1 - y2;

  return Math.sqrt(a * a + b * b);
};

const getAngle = (p1, p2) => Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);

const getMarkers = data =>
  data.reduce((r, d) => {
    if (allMarkers[d]) {
      r.push(allMarkers[d]);
    }

    if (!allMarkers[d]) {
      console.log(`Missing coords for: ${d}`);
    }

    return r;
  }, []);

const extent = (data, key) => {
  return data.reduce(
    (r, d) => {
      const val = parseFloat(d[key]);
      r[0] = r[0] !== null ? Math.min(val, r[0]) : val;
      r[1] = r[1] !== null ? Math.max(val, r[1]) : val;
      return r;
    },
    [null, null]
  );
};
