import React, { useEffect, useState } from "react";
import Builder from "components/Builder";
import Loading from "components/Loading"

const narrativeQuery = `
    query Narrative {
    entries(section:"narrative",type:"narrative"){
      ...on narrative_narrative_Entry {
        title
        builder {
          ...on builder_heading_BlockType{
            id
            typeHandle
            text
          }
          ...on builder_text_BlockType {
            id,
            typeHandle
            text
          }
          ...on builder_things_BlockType {
            id
            typeHandle
            things {
              col1
            }
          }
          ...on builder_staticPlaceholder_BlockType{
            id
            typeHandle
            heading
            typeHandle
          }
          ...on builder_moving_BlockType{
            id
            typeHandle
          }
          ...on builder_cityIndex_BlockType {
            id
            typeHandle
          }
          ...on builder_thisMoment_BlockType {
            id
            typeHandle
          }
        }
      }
    }
  }
`

const Narrative = () => {
  const { loading, error, data } = useData(narrativeQuery)

  return loading
    ? <Loading />
    : error
     ? "Error"
     : <Builder blocks={data.entries[0].builder} />
}

const useData = (query) => {
  const [data,setData] = useState()
  useEffect(() => {

    fetch(`http://wework.test/api?query=${encodeURIComponent(query)}`)
      .then(r => r.json())
      .then(data => setData(data));
  }, [query])

  if (!data) {
    return { loading: true, error: false, data: {} }
  }
  if (data.errors) {
    console.log(data.errors)
    return { loading: false, error: true, data: {} }
  }

  return { loading: false, error: false, data: data.data }
}

export default Narrative
