import React from "react";
import { HowDidWe } from "components/HowDidWeButton";
import "./NumberBlock.scss";

export default ({ copy, num, color, tooltip }) => {
  const style = { color: color };

  return (
    <li className="NumberBlock">
      <div>
        <h3 className="NumberBlock-number" style={style}>
          {num}
        </h3>
      </div>
      <div className="PercentageBlock-text">
        <div className="PercentageBlock-copy">
          {copy} {tooltip && <HowDidWe text={tooltip} place={"top"} />}
        </div>
      </div>
    </li>
  );
};
