import React, { useState, useEffect } from "react";
import { Waypoint } from "react-waypoint";

const AsyncComp = ({ children, onSetActive }) => {
  const [active,setActive] = useState(false)
  useEffect(() => {
    if (onSetActive) {
      onSetActive(active)
    }
  },[active,onSetActive])
  return (
    <Waypoint bottomOffset={-window.innerHeight}onEnter={() => setActive(true)}>
      {active ? children : null}
    </Waypoint>
  )
}

export default AsyncComp;