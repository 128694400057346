import React from "react";
import { percentBlockColors } from "components/PercentageBlock";
import "./ChartKey.scss";

export default ({ data, colors, className = "", offset = 1 }) => {
  return (
    <div className={`ColorKey ${className}`}>
      <h3 className="type-uppercase">Key</h3>
      <ul className="Flex-list">
        {data.map((d, i) => {
          return (
            <li key={i} className="ColorKey-key">
              <span
                className="ColorKey-circle"
                style={{
                  backgroundColor: colors.length
                    ? percentBlockColors[colors[i]][0]
                    : colors[i + offset]
                }}
              />
              <p className="ff-mono-regular">{d}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
