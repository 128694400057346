import React from "react";
import { scaleLinear } from "d3-scale";

import "./CityTopChart.scss";

const BLOCK_WIDTH = 55;
const BLOCK_HEIGHT = 35;
const PAD = 5;
let yOffset = 0;

export default ({ data, title, axis, colors, tooltips }) => {
  const sorted = Object.keys(data).sort((a, b) => {
    return parseFloat(data[b]) - parseFloat(data[a]);
  });
  const colorScale = Object.keys(colors).length < 10 ? [7, 1] : [1, 5]; // we're inversing the color index for greens...
  yOffset = 0;

  const vals = sorted.filter(val => ["Region", "Other"].indexOf(val) < 0);
  const maxVal = parseInt(data[vals[0]]);

  const scale = scaleLinear()
    .domain([1, maxVal])
    .range(colorScale);

  return (
    <div>
      <h3 className="CityTopChart-title">{title}</h3>
      <div className="CityTopChart">
        <aside className="CityTopChart-Y-axis">
          {axis.map(ax => (
            <span key={ax}>{ax}</span>
          ))}
        </aside>
        <ul className="CityTopChart-list">
          {vals.slice(0, 9).map(key => {
            let val = Math.round(parseFloat(data[key]));
            return (
              <li
                key={key}
                className="CityTopChart-list-item"
                data-tip={tooltips && tooltips[key]}
              >
                <svg
                  className="CityTopChart-list-item-value"
                  viewBox={`0 0 ${BLOCK_WIDTH} ${BLOCK_HEIGHT}`}
                >
                  <VizGroup
                    key={key}
                    value={val}
                    label={key}
                    fill={colors[Math.round(scale(val))]}
                    tooltip={tooltips && tooltips[key]}
                  />
                </svg>
                <div
                  className="CityTopChart-list-item-label"
                  key={`title_${key}`}
                >
                  <div className="CityTopChart-list-item-label-text">{key}</div>
                  {tooltips && tooltips[key] && (
                    <button className="CityIndexScroll-HowDidWeButton">
                      ?
                    </button>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const VizGroup = ({ label, value, fill }) => {
  const y = yOffset;
  // yOffset += BLOCK_HEIGHT + PAD;

  // TODO: is there a better way to do this?
  const dark = ["#18383C", "#005056", "#008A77"];
  const copyColor = dark.indexOf(fill) < 0;
  return (
    <g>
      <rect width={BLOCK_WIDTH} height={BLOCK_HEIGHT} x={0} y={y} fill={fill} />
      <text
        y={y}
        x={BLOCK_WIDTH / 2}
        className={"CityTopChart-label"}
        fontSize={10}
        fill={copyColor ? "#000000" : "#ffffff"}
        textAnchor={"middle"}
        dominantBaseline={"central"}
        dy={BLOCK_HEIGHT / 2}
      >
        {value}%
      </text>
      <text
        fontFamily={"ApercuPro"}
        x={BLOCK_WIDTH + PAD * 2}
        y={y}
        fontSize={12}
        dominantBaseline={"central"}
        dy={BLOCK_HEIGHT / 2 + 1}
      >
        <tspan>{label} </tspan>
      </text>
    </g>
  );
};
