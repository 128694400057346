import React from "react";
import EconOptimism from "./EconOptimism";
import { CityIndexInteractive } from "components/CityIndex";
// import Moving from "components/Moving";
// import HealthInsurance from "components/HealthInsurance";
import MovingMap from "components/MovingMap";
import InfrastructureMap from "./InfrastructureMap";
import HousingWalkability from "components/HousingWalkability";
// import CityGlobal from "components/CityGlobal";
import CityIndexScroller from "components/CityIndexScroller";
// import PercentageBlockGrid from "components/PercentageBlockGrid";
// import PercentageBlock from "components/PercentageBlock";

import FlowChart from "components/FlowChart";
// import NpsMoving from "components/NpsMoving";
import ChallengesComparison from "components/ChallengesComparison";
import DotMatrix from "components/DotMatrix";
import PercentComparisonCards from "./PercentComparisonCards";

import StackedBlock from "components/StackedBlock"
import BarGraph from "components/BarGraph"
// import RadialGraph from "components/RadialGraph"

// import FetchDataViz from "components/FetchDataViz"

import { useCsv } from "Fetch";

import indexSrc from "data/CityIndex.csv";
import hiringData from "data/hiring.csv";
import healthInsurance from "data/HealthInsurance.csv";
import values from "data/company-values.csv";
import automation from "data/automation.csv";
// import innovationDummy from "data/dummy-radial-data.csv";

// const percBlockItems = [
//   {
//     perc: 62,
//     city: 'Madrid',
//     copy: 'of businesses in Madrid say they will be hiring technical talent most',
//     color: 4
//   },
//   {
//     perc: 12,
//     city: 'Madrid',
//     copy: 'of businesses in Madrid say they will be hiring technical talent most',
//     color: 4
//   },
//   {
//     perc: 56,
//     city: 'Madrid',
//     copy: 'of businesses in Madrid say they will be hiring technical talent most',
//     color: 4
//   },
// ]
//
// const percBlockItems2 = [
//   {
//     perc: [82, 34],
//     color: [5,1],
//     city: 'Madrid',
//     copy: 'of businesses in Madrid say they will be hiring technical talent most',
//     subcopy: 'lorem ipsum dolor sit amet...'
//   },
//   {
//     perc: [75, 14],
//     color: [5,1],
//     city: 'Madrid',
//     copy: 'of businesses in Madrid say they will be hiring technical talent most',
//     subcopy: 'lorem ipsum dolor sit amet...'
//   },
//   {
//     perc: [60,43],
//     color: [5,1],
//     city: 'Madrid',
//     copy: 'of businesses in Madrid say they will be hiring technical talent most',
//     subcopy: 'lorem ipsum dolor sit amet...'
//   },
// ]

const Visualizations = () => {
  const indexData = useCsv(indexSrc)
  const hireData = useCsv(hiringData)
  const insuranceData = useCsv(healthInsurance)
  const valueData = useCsv(values)
  const automationData = useCsv(automation)
  // const innovationData = useCsv(innovationDummy)

  return <section className={"Visualizations"}>
    <PercentComparisonCards />
    <DotMatrix />
    <EconOptimism data={indexData} />
    <HousingWalkability data={indexData} />
    <ChallengesComparison />
    <section>
      <InfrastructureMap data={indexData} />
      <MovingMap />
    </section>
    <CityIndexScroller />
    <CityIndexInteractive />
    <section>
      <FlowChart data={hireData} />
      <FlowChart data={insuranceData} />
    </section>

    <section>
      <StackedBlock data={valueData} />
    </section>

    <section>
      <BarGraph data={automationData} />
    </section>
    {/*<section>*/}
    {/*  <FetchDataViz slug={"innovation-data"} />*/}
    {/*</section>*/}
    {/*<FetchDataViz slug={"climate-change"} />*/}
  </section>
}

export default Visualizations
