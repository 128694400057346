import React from "react";
import { useHistory } from "react-router-dom";
import BackArrow from "svg/BackArrow.svg";

const GoBack = () => {
  const history = useHistory();
  return (
    <button className="CityPage-back" onClick={() => history.goBack()}>
      <div>Go back</div>
      <img src={BackArrow} alt={"Arrow pointing back"} />
    </button>
  );
};

export default GoBack;
