import React from "react";
import { Waypoint } from "react-waypoint";
import { a, useSpring, config } from "react-spring";
import useBreakpoint from "components/useBreakpoint";
import { indexcolors } from "../constants";
import { useCsvStore } from "Fetch";
import Loading from "./Loading";
import ShowMore from "components/ShowMore";

import "./BarGraph.scss";

const WIDTH = 800;
const HEIGHT = 65;
const PADDING = 20;
const MOBILE_HEIGHT = HEIGHT / 2;
const MOBILE_WIDTH = WIDTH;

const BarGraph = ({ csv }) => {
  const breakpoint = useBreakpoint();
  const res = useCsvStore(csv[0].url);

  if (!res) return <Loading />;

  const data = res[0];

  const initialKeys = Object.keys(data).slice(0, 10);
  const remainingKeys = Object.keys(data).slice(10);

  return (
    <section className={`BarGraph BarGraph--${breakpoint}`}>
      {breakpoint === "desktop" ? (
        <>
          <Graph keys={initialKeys} data={data} />
          <ShowMore count={remainingKeys.length}>
            <Graph keys={remainingKeys} data={data} />
          </ShowMore>
        </>
      ) : (
        <div className="BarGraph-Graph">
          <MobileGraph keys={initialKeys} data={data} />
          <ShowMore count={remainingKeys.length}>
            <MobileGraph keys={remainingKeys} data={data} />
          </ShowMore>
        </div>
      )}
    </section>
  );
};

export default BarGraph;

const Graph = ({ keys, data }) => {
  const columnCount = keys.length;
  const style = {
    height: `${HEIGHT - PADDING}px`,
    padding: "0 1rem",
    marginBottom: `${PADDING}px`,
    display: "flex",
    alignItems: "center"
  };
  return (
    <>
      <aside>
        <ul className="BarGraph-Axis--Y">
          {keys.map(title => (
            <li key={title} style={style}>
              {title}
            </li>
          ))}
        </ul>
      </aside>
      <div className="BarGraph-Graph">
        <svg
          viewBox={`0 0 ${WIDTH} ${HEIGHT * columnCount}`}
          preserveAspectRatio={"none"}
          style={{
            height: `${HEIGHT * columnCount}px`,
            width: "auto",
            display: "block",
            maxWidth: "100%"
          }}
        >
          {keys.map((key, i) => {
            return (
              <Bar
                key={`${key}_${i}`}
                title={key}
                value={data[key]}
                index={i}
                count={columnCount}
              />
            );
          })}
        </svg>
      </div>
    </>
  );
};

const Bar = ({ value, index }) => {
  const fill = indexcolors["1"];
  const backgroundFill = indexcolors["1_tint"];
  const yVal = HEIGHT * index;
  const fillVal = WIDTH * (value / 100);
  const textX = value <= 90 ? fillVal + PADDING : fillVal - PADDING;

  const [barStyle, setBarStyle] = useSpring(() => ({
    width: "0px",
    config: config.stiff
  }));

  const [textStyle, setTextStyle] = useSpring(() => ({
    opacity: 0,
    config: config.stiff
  }));

  function enter() {
    setBarStyle({
      width: `${fillVal}px`,
      delay: 50 * index
    });

    setTextStyle({
      opacity: 1,
      delay: 60 * index
    });
  }

  return (
    <Waypoint
      onEnter={() => {
        enter();
      }}
    >
      <g style={{ height: HEIGHT + PADDING }}>
        <rect
          x={0}
          y={yVal}
          width={WIDTH}
          height={HEIGHT - PADDING}
          fill={backgroundFill}
        />
        <a.rect
          x={0}
          y={yVal}
          width={fillVal}
          height={HEIGHT - PADDING}
          fill={fill}
          style={barStyle}
        />
        <a.text
          x={textX}
          y={yVal + HEIGHT}
          fill={"#000000"}
          fontSize={24}
          dy={-34}
          dx={value <= 90 ? 0 : -40}
          style={textStyle}
        >{`${value}%`}</a.text>
      </g>
    </Waypoint>
  );
};

const MobileGraph = ({ data, keys }) => {
  return (
    <>
      {keys.map((datum, i) => {
        return (
          <MobileBar
            key={`${datum}_${i}`}
            title={datum}
            value={data[datum]}
            index={i}
          />
        );
      })}
    </>
  );
};

const MobileBar = ({ value, title, index }) => {
  const fill = indexcolors["1"];
  const backgroundFill = indexcolors["1_tint"];
  const fillVal = MOBILE_WIDTH * (value / 100);

  const [props, setProps] = useSpring(() => ({
    width: 0,
    opacity: 0,
    config: config.stiff
  }));

  function enter() {
    setProps({
      width: fillVal,
      opacity: 1
    });
  }

  return (
    <Waypoint
      onEnter={() => {
        console.log("ENTER");
        enter();
      }}
    >
      <div className="MobileBarGraph">
        <a.div
          style={{ opacity: props.opacity }}
        >{`${title} - ${value}%`}</a.div>
        <svg
          viewBox={`0 0 ${MOBILE_WIDTH} ${MOBILE_HEIGHT}`}
          style={{
            display: "block",
            margin: "0 auto"
          }}
        >
          <rect
            y={0}
            x={0}
            width={MOBILE_WIDTH}
            height={MOBILE_HEIGHT}
            fill={backgroundFill}
          />
          <a.rect
            y={0}
            x={0}
            width={props.width}
            height={MOBILE_HEIGHT}
            fill={fill}
          />
        </svg>
      </div>
    </Waypoint>
  );
};
