import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { csvParse } from "d3-dsv";
import { fetchCsv, requestData } from "./redux/actions";
import indexSrc from "data/CityIndex.csv";

import { INDICES as indices, indexcolors as colors } from "./constants";
import orderBy from "lodash/orderBy";
import keys from "keys";

export const useData = url => {
  const [data, setData] = useState();
  useEffect(() => {
    fetch(`/${url}`)
      .then(res => res.json())
      .then(res => {
        setData(res);
      });
  }, [url]);
  return data;
};

export const useApi = (query, variables) => {
  // const [data,set] = useState()
  const key = query + JSON.stringify(variables);
  const data = useSelector(({ data }) => {
    return data[key];
  });
  const dispatch = useDispatch();
  const body = JSON.stringify({
    query,
    variables
  });

  useEffect(() => {
    if (!data) {
      let active = true;
      fetch(`/api`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      })
        .then(res => res.json())
        .then(res => {
          active &&
            dispatch({
              type: "STORE_DATA",
              endpoint: key,
              data: res
            });
        });

      return () => {
        active = false;
      };
    }
  }, [data, dispatch, key, body]);

  return data;
};

export const useCsv = src => {
  const [data, setData] = useState();
  useEffect(() => {
    fetch(src)
      .then(res => res.text())
      .then(res => {
        setData(csvParse(res));
      });
  }, [setData, src]);
  return data;
};

export const useCsvStore = endpoint => {
  const [data, requested] = useSelector(({ data }) => {
    return [data[endpoint], data.requested.includes(endpoint)];
  });
  const dispatch = useDispatch();
  if (!data && !requested && typeof window !== "undefined") {
    //prevent fetch during ssr serve
    dispatch(requestData(endpoint));
    dispatch(fetchCsv(endpoint));
  }
  return data;
};

export const useIndex = () => useCsvStore(indexSrc);

export const useIndexLayouts = (dimensions, reverseScale) => {
  const data = useIndex();
  const dispatch = useDispatch();
  const key = dimKey(dimensions, reverseScale);
  const layout = useSelector(({ indexLayouts }) => indexLayouts[key]);

  if (
    typeof layout === "undefined" &&
    dimensions.width > 0 &&
    dimensions.height > 0
  ) {
    dispatch({
      type: "REQUESTED_LAYOUT",
      key
    });
    dispatch({
      type: "SAVE_LAYOUT",
      key,
      value: getLayouts(data, dimensions, reverseScale)
    });
  }

  return layout;
};

const dimKey = ({ width, height, reverseScale }) =>
  `${width}_${height}${reverseScale ? "_reverse" : ""}`;

const getLayouts = (data, { width, height }, reverseScale) => {
  const fives = index => {
    return data.reduce((r, d) => {
      if (+d[index] === 5 || d[index] === 4) {
        r++;
      }
      return r;
    }, 0);
  };

  const byIndex = indices
    .sort((a, b) => {
      return fives(a) > fives(b) ? -1 : fives(a) < fives(b) ? 1 : 0;
    })
    .reduce((r, index) => {
      r[index] = dataByIndex(data, index, reverseScale ? "desc" : "asc");
      return r;
    }, {});

  const all = Object.values(byIndex).reduce((r, d) => {
    return [...r, ...d];
  }, []);

  return indices.reduce(
    (r, indexA) => {
      indices.forEach(indexB => {
        r[`${indexA}${indexB}`] = getLayout(
          all,
          indices,
          indexA,
          indexB,
          width,
          height
        );
      });
      return r;
    },
    {
      allall: getLayout(all, indices, "all", "all", width, height)
    }
  );
};

const dataByIndex = (data, currentIndex, indexOrder) => {
  const result = data.map((d, i) => ({
    ...d,
    ...indices.map(ind => ({ [ind]: +d[ind] })),
    itemKey: `${currentIndex}-${d[keys.label]}`,
    index: currentIndex,
    value: +d[currentIndex]
  }));
  return sortAlphaScore(result, currentIndex, indexOrder);
};

const getLayout = (items, indices, currentIndex, zoomIndex, width, height) => {
  const PADDING = 3;
  const columns = currentIndex === "all" ? 50 : 5;
  const itemWidth = width / 50;
  const itemHeight = height / 10;
  const itemWidthZoomed = width / 5;
  const itemHeightZoomed = height / 11;

  let allColumnCount = -1;
  let allRowCount = 1;
  let zoomColumnCount = -1;

  return items.map((item, i) => {
    if (allColumnCount + 1 >= columns) {
      allColumnCount = 0;
      allRowCount++;
    } else {
      allColumnCount++;
    }

    const isCurrent = item.index === zoomIndex;
    const indexCurrent = indices.indexOf(zoomIndex);

    if (isCurrent) {
      if (zoomColumnCount + 1 >= columns) {
        zoomColumnCount = 0;
      } else {
        zoomColumnCount++;
      }
    }

    const indexRank =
      currentIndex !== "all"
        ? items.find(
            d => d[keys.label] === item[keys.label] && d.index === currentIndex
          ).indexRank
        : null;

    const x =
      currentIndex === "all" || !isCurrent
        ? itemWidth * (i % 50) + 1
        : itemWidthZoomed * (indexRank % columns) + 1;

    const y =
      currentIndex === "all"
        ? itemHeight * allRowCount + (5 * PADDING) / 2
        : isCurrent
        ? itemHeightZoomed * Math.floor(1 + indexRank / columns)
        : indices.indexOf(item.index) < indexCurrent
        ? -itemHeight
        : height;

    return {
      isCurrent,
      label: item[keys.label],
      x: x,
      y: y,
      textOpacity: isCurrent ? 1 : 0,
      width: isCurrent ? itemWidthZoomed : itemWidth,
      height: isCurrent ? itemHeightZoomed : itemHeight - PADDING * 5,
      fill: isCurrent ? colors[item[currentIndex]] : colors[item.value],
      fill_tint: isCurrent
        ? colors[`${item[currentIndex]}_tint`]
        : colors[`${item.value}_tint`]
    };
  });
};

const sortAlphaScore = (data, index = "value", indexOrder = "asc") => {
  return orderBy(
    data,
    [index, keys.label],
    [indexOrder, "asc"]
  ).map((d, i) => ({ ...d, indexRank: i }));
};
