export default [
  {
    ratio: "46 of 50",
    ratioLabel: "cities have thriving communities of creators",
    text:
      "<p>People in almost every city give their city a high score on the City Index when it comes to providing a thriving, creative, maker, and artistic community.</p>",
    cityIndexColumn: "Creative"
  },
  {
    ratio: "39 of 50",
    ratioLabel: "cities have a strong sense of community",
    text:
      "<p>People in most cities give their city a high score on whether it provides a sense of community, fosters inclusion, and welcomes diversity.</p><p>In the U.S. where we asked about racial identity, people of color more often identify “equity, diversity, and inclusion” as an important characteristic in their city when asked what’s important to them: 42 percent of people of color identified “equity, diversity, and inclusion” as a top-three characteristic, compared to just 29 percent of those who identify as White. </p>",
    cityIndexColumn: "Community"
  },
  {
    ratio: "34 of 50",
    ratioLabel: "cities are good places for families",
    text:
      "<p>People in most cities give their city a high score as a good place to raise a family and for children.</p>",
    cityIndexColumn: "Family"
  }
];
