import React from "react"
import { Link } from "react-router-dom";
import "./CityList.scss";
export default ({ items, fill }) => {
  return <div className={"CityList"}>
    {items.map(item => <div key={item} className={"CityList-item"} style={{ backgroundColor: fill }}>
      <Link to={`/city/${item}`}>{item}</Link>
    </div>)}
  </div>
}
