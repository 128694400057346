export const INDICES = [
  "Economy",
  "Infrastructure",
  "Business Friendliness",
  "Housing",
  "Innovation",
  "Creative",
  "Community",
  "Family",
  "Walkability"
];

export const indexQuestions = {
  Economy:
    "I am optimistic about my city’s economic conditions in the next 2 years, and believe it is a good place to find a job or grow a business.",
  Infrastructure:
    "I am satisfied with the quality of public infrastructure in my city (roads, transportation, transit, cleanliness, water).",
  // eslint-disable-next-line
  ["Business Friendliness"]:
    "My city, in terms of taxes and regulations (“Red Tape”) is friendly to entrepreneurs and businesses, and is a good place to start or grow a company.",
  Housing:
    "I am satisfied with the quality and affordability of housing in my city.",
  Innovation: "My city has a thriving innovation ecosystem.",
  Creative: "My city has a thriving creative, maker and artistic community.",
  Community:
    "My city has a strong sense of community and creates a culture of inclusion and belonging that is welcome to diversity.",
  Family: "This is a good city for families and raising children.",
  Walkability:
    "My city is very walkable and I am very satisfied with the overall urban environment of my city."
};

export const SCORES = [1, 2, 3, 4, 5];

export const indexcolors = {
  5: "#3FA7C3",
  "5_tint": "#B2DCE6",
  4: "#92D5E0",
  "4_tint": "#D3EEF3",
  3: "#F8F1EB",
  "3_tint": "#FBF9F7",
  2: "#FCDB8B",
  "2_tint": "#FEF1D1",
  1: "#F7AF43",
  "1_tint": "#FAD699"
};

export const greens = {
  1: "#18383C",
  2: "#005056",
  3: "#008A77",
  4: "#00A18D",
  5: "#25BDA3",
  6: "#94DDB7",
  7: "#D4F1E1"
};

export const comparison = {
  1: "#18383C",
  "1_tint": "#9fb8ba",
  2: "#008A77",
  "2_tint": "#b5e3da",
  3: "#25BDA3",
  "3_tint": "#D4F1E1",
  4: "#94DDB7",
  "4_tint": "#f0f9f3",
}

export const duogreen = {
  default: "#D5F2E2",
  filled: "#005056"
};
