import React from "react";
import { scaleLinear, scaleBand } from "d3-scale";
import { Waypoint } from "react-waypoint";
import { a, useSpring, config } from "react-spring";
import useBreakpoint from "./useBreakpoint";

const width = 1200;
const height = 600;
const padding = {
  left: 80,
  right: 30,
  top: 0,
  bottom: 100
};
const vizheight = height - padding.top - padding.bottom;
const vizwidth = width - padding.left - padding.right;

const fontMono = "ApercuWeWorkMono-Regular";
// const fontReg = 'ApercuPro'

const data = {
  "Up to $50k": 61,
  "$50k - $100k": 67,
  "$100k - 200k": 73,
  "$200k+": 75
};

const colors = ["#006178", "#0087B1", "#3FA7C3", "#92D5E0"];
const fill = {
  black: "#2B2B33",
  white: "#fff"
};

export default () => {
  const keys = Object.keys(data);
  const offset = width / (4 * keys.length);
  const scaleX = scaleBand()
    .domain(keys)
    .range([padding.left, vizwidth + offset * (keys.length - 1)]);

  const posX = scaleBand()
    .domain(keys)
    .range([padding.left, vizwidth - offset]);

  const scaleY = scaleLinear()
    .domain([0, 100])
    .range([0, vizheight]);

  const breakpoint = useBreakpoint();

  const [rectHeight, setRectHeight] = useSpring(() => ({
    transform: "scaleY(0)",
    transformOrigin: "bottom",
    config: config.stiff
  }));

  const [text, setText] = useSpring(() => ({
    opacity: 0
  }));

  function enter() {
    setRectHeight({
      transform: "scaleY(1)",
      delay: 100
    });

    setText({
      opacity: 1,
      delay: 150
    });
  }

  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      {keys.reverse().map((key, i) => (
        <Waypoint
          onEnter={() => {
            enter();
          }}
          key={key}
        >
          <g>
            <a.text
              style={text}
              x={posX(key)}
              y={vizheight - scaleY(data[key]) - padding.right / 2}
              textAnchor={"start"}
              fontFamily={fontMono}
              fontSize={breakpoint === "desktop" ? 14 : 28}
            >
              {key}
            </a.text>
            <a.rect
              x={posX(key)}
              y={vizheight - scaleY(data[key])}
              width={scaleX.bandwidth()}
              height={scaleY(data[key])}
              style={rectHeight}
              fill={colors[i]}
            />
            <a.text
              style={text}
              fill={i === keys.length - 1 ? fill.black : fill.white}
              fontSize={fontSize.viz}
              fontFamily={fontMono}
              x={posX(key) + scaleX.bandwidth() - fontSize.viz / 2}
              y={vizheight - scaleY(data[key]) + fontSize.viz * 1.5}
              textAnchor={"end"}
            >
              {data[key]}%
            </a.text>
          </g>
        </Waypoint>
      ))}
      <YAxis stroke={colors[2]} label={"Level of Optimism"} />
    </svg>
  );
};

// <XAxis transform={`translate(0 ${vizheight})`} ticks={keys} x1={padding.left} x2={vizwidth} label="Income Range" scale={posX} stroke={colors[2]} />

const YAxis = ({ stroke, label }) => {
  return (
    <g>
      <text
        x={0}
        y={vizheight / 2 + padding.left / 2 - fontSize.axis * 1.5}
        transform={`rotate(-90 ${0} ${vizheight / 2})`}
        textAnchor={"middle"}
        dominantBaseline={"middle"}
        style={{ textTransform: "uppercase", fontWeight: 500 }}
      >
        {label}
      </text>
      <text
        x={padding.left / 2}
        y={fontSize.axis * 1.5}
        fontSize={fontSize.axis}
        fontFamily={fontMono}
        textAnchor={"middle"}
      >
        100
      </text>
      <line
        x1={padding.left / 2}
        x2={padding.left / 2}
        y1={fontSize.axis * 2}
        y2={height - padding.bottom}
        stroke={stroke}
      />
      <line
        x1={padding.left / 2}
        x2={padding.left / 2 - 10}
        y1={fontSize.axis * 2}
        y2={fontSize.axis * 2}
        transform={`rotate(315 ${padding.left / 2} ${fontSize.axis * 2})`}
        stroke={stroke}
      />
      <line
        x1={padding.left / 2}
        x2={padding.left / 2 - 10}
        y1={fontSize.axis * 2}
        y2={fontSize.axis * 2}
        transform={`rotate(225 ${padding.left / 2} ${fontSize.axis * 2})`}
        stroke={stroke}
      />
      <text
        x={padding.left / 2}
        y={vizheight + padding.top + padding.right / 2}
        fontSize={fontSize.axis}
        textAnchor={"middle"}
        dominantBaseline={"middle"}
      >
        0
      </text>
    </g>
  );
};

const fontSize = {
  axis: 12,
  viz: 32
};
// const XAxis = ({ ticks, x1, x2, scale, label, stroke="#000", ...rest }) => {
//   return <g {...rest}>
//     <line x1={x1} x2={x2} y1={padding.left/2} y2={padding.left/2} stroke={stroke} />
//     <line
//       x1={x2} x2={x2 - 10}
//       y1={padding.left/2} y2={padding.left/2}
//       transform={`rotate(45 ${x2} ${padding.left/2})`}
//       stroke={stroke} />
//     <line
//       x1={x2} x2={x2 - 10}
//       y1={padding.left/2} y2={padding.left/2}
//       transform={`rotate(-45 ${x2} ${padding.left/2})`}
//       stroke={stroke} />
//     {ticks.map(tick => (
//       <text
//         key={tick}
//         x={scale(tick) + scale.bandwidth() + padding.left}
//         y={fontSize.axis + padding.left*.6}
//         fontSize={fontSize.axis}
//         fontFamily={fontMono}
//         textAnchor={"middle"}
//         dominantBaseline={"middle"}>
//         {tick}
//       </text>
//     ))}
//     <text x={x1 + (x2+x1)/2} y={fontSize.axis*7.5} textAnchor={"end"} style={{ textTransform: "uppercase", fontWeight: 500 }} fontFamily={fontReg}>{label}</text>
//   </g>
// }
