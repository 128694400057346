import React, { useState } from "react";
import { useCsvStore } from "Fetch";
import { useHistory } from "react-router-dom";
import { comparison, greens } from "../constants";
import keys from "keys";
import { ItemContent } from "./CityIndexScroller";
import Tooltip from "react-tooltip";
import useBreakpoint from "../components/useBreakpoint";
import "./Comparison.scss";
import "../components/MovingMap.scss";

const width = 1000;
const height = 50;
const cities = 50;
const unitw = width / cities;

export default ({ csv, columns, ratio, ratioLabel, heading }) => {
  const data = useCsvStore(csv[0].url);
  const [hovered, setHovered] = useState();
  const breakpoint = useBreakpoint();

  if (!data) {
    return null;
  }

  const dataWithTooltip = data.map(d => ({
    ...d,
    tooltip: makeTooltip(d, columns)
  }));

  const ttid = "TT_" + csv.url;

  return (
    <div className={"Comparison"}>
      <ItemContent ratio={ratio} ratioLabel={ratioLabel} color={greens[2]} />
      <div>
        <h3 className="Comparison-heading">{heading}</h3>
        <Key />
        {columns.map(({ column }) => {
          return (
            <div key={column} className={"Comparison-item"}>
              <div className={"Comparison-item-label"}>{column}</div>
              <Index
                data={dataWithTooltip}
                column={column}
                hovered={hovered}
                setHovered={setHovered}
                ttid={ttid}
              />
            </div>
          );
        })}
      </div>
      <Tooltip
        id={ttid}
        className="Tooltip ComparisonTooltip"
        overridePosition={
          breakpoint === "desktop"
            ? null
            : ({ left, top }, currentEvent, currentTarget, node) => {
                const d = document.documentElement;
                left = Math.min(d.clientWidth - node.clientWidth, left);
                top = Math.min(d.clientHeight - node.clientHeight, top);
                left = Math.max(0, left);
                top = Math.max(0, top);
                return { top, left };
              }
        }
      />
    </div>
  );
};

const Index = ({ data, column, hovered, setHovered, ttid }) => {
  const history = useHistory();

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio={"none"}
      className={"Comparison-item-viz"}
      style={{ height: `${height}px`, width: "100%" }}
    >
      {sort(data, column).map((d, i) => {
        const value = Math.min(+d[column], 4);
        const fill = comparison[value];
        return (
          <rect
            key={d[keys.label]}
            width={unitw}
            height={height}
            x={unitw * i + 1}
            y={0}
            fill={
              hovered && hovered !== d[keys.label]
                ? comparison[`${value}_tint`]
                : fill
            }
            stroke={"#fff"}
            data-tip={d.tooltip}
            data-for={ttid}
            data-html
            onClick={() => {
              history.push(`/city/${d["Region"]}`);
            }}
            onMouseEnter={() => {
              setHovered(d[keys.label]);
            }}
            onMouseLeave={() => {
              setHovered(null);
            }}
          />
        );
      })}
    </svg>
  );
};
const Key = () => {
  const keys = [1, 2, 3, 4];
  return (
    <div className={"ComparisonKey"}>
      <div className={"ComparisonKey-label"}>Key</div>
      {keys.map((d, i) => (
        <div
          key={d}
          className="ComparisonKey-item"
          style={{
            backgroundColor: comparison[d],
            color: i < 2 ? "#fff" : "#000"
          }}
        >
          Rank {i === keys.length - 1 ? `${d}+` : d}
        </div>
      ))}
    </div>
  );
};

const sort = (data, column) => data.sort((a, b) => a[column] - b[column]);

const makeTooltip = (d, columns) => {
  const cols = [...columns];
  return `<div class="ComparisonTooltip-inner">
    <div class="ComparisonTooltip-inner-region">${d[keys.label]}</div>
    <ul class="ComparisonTooltip-inner-list">
      ${cols
        .sort((a, b) => {
          return +d[a.column] - +d[b.column];
        })
        .map(({ column }) => {
          const value = Math.min(+d[column], 4);
          const fill = comparison[value];
          return `<li class="ComparisonTooltip-inner-list-item">
          <div class="ComparisonTooltip-inner-list-item-value" style="background-color: ${fill}"></div>
          ${column}
        </li>`;
        })
        .join("")}
    </ul>
  </div>`;
};
