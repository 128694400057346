import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Nav from "components/Nav";
import ScrollMemory from "react-router-scroll-memory";

import Narrative from "components/Narrative";
import Visualizations from "components/Visualizations";
import Intro from "components/Intro";
import Home from "components/Home";
import About from "components/About";
import CityPage from "components/CityPage";
import { KeyFindingPage } from "blocks/KeyFinding";
import { HowDidWeTooltip } from "components/HowDidWeButton";
import Missing from "components/Missing";

import "./App.scss";
import { Provider } from "react-redux";
import createStore from "redux/store";

const store = createStore();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <InnerApp />
      </Router>
    </Provider>
  );
}

export const InnerApp = () => {
  return (
    <div>
      <ScrollMemory />
      <Nav />
      <Switch>
        <Route path="/visualizations" component={Visualizations} />
        <Route path="/narrative" component={Narrative} />
        <Route path="/intro" component={Intro} />
        <Route path="/about" component={About} />
        <Route path="/city/:name" component={CityPage} />
        <Route path="/:slug" component={KeyFindingPage} />
        <Route path="/" component={Home} />
        <Route component={Missing} />
      </Switch>
      <>
        <HowDidWeTooltip place="left" />
        <HowDidWeTooltip place="right" />
        <HowDidWeTooltip place="top" />
        <HowDidWeTooltip place="bottom" />
      </>
    </div>
  );
};

export default App;
