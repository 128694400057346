// const Remap = ({
//   from: { low: fromLow, high: fromHigh },
//   to: { low: toLow, high: toHigh }
// }) => value => {
//   const mapped =
//     ((value - fromLow) * (toHigh - toLow)) / (fromHigh - fromLow) + toLow;
//   return Math.max(Math.min(mapped, toHigh), toLow);
// };

// export default Remap;

// const remap = (x, r1, r2) => {
//   const mapped = r2[0] + ((x - r1[0]) / (r1[1] - r1[0])) * (r2[1] - r2[0]);
//   return Math.max(Math.min(mapped, r1[1]), r2[0]);
// };

const remap = (x, r1, r2) => {
  const mapped = ((x - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
  return Math.max(Math.min(mapped, r2[1]), r2[0]);
};

export default remap;
