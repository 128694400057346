const defaultState = {
  requested: []
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "STORE_DATA":
      return {
        ...state,
        [action.endpoint]: action.data
      }

    case "REQUEST_DATA":
      return {
        ...state,
        requested: [
          ...state.requested,
          action.endpoint
        ]
      }

    default:
      return state
  }
}
