import React from "react";
import { page as query } from "queries";
import Builder from "./Builder";
import { useApi } from "Fetch";
import { PageLoading } from "./Loading";

const Home = () => {
  const res = useApi(query, { slug: "__home__" });

  if (!res) {
    return <PageLoading />;
  }

  return <HomeView blocks={res.data.entries[0].pageBuilder} />;
};

const HomeView = ({ blocks }) => {
  return (
    <div className="Home">
      <Builder blocks={blocks} />
    </div>
  );
};

export default Home;
