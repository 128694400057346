const defaultState = {}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "SAVE_LAYOUT":
      return {
        ...state,
        [action.key]: action.value
      }

    case "REQUESTED_LAYOUT":
      return {
        ...state,
        [action.key]: state[action.key] || null
      }

    default:
      return state
  }
}
