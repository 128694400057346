import React, { useEffect, useRef } from "react";
import { scaleLinear } from "d3-scale";
import { forceSimulation, forceManyBody, forceCollide } from "d3-force";
import { select } from "d3-selection";
import Tooltip from "react-tooltip";
import keys from "keys";
import { useIndex } from "Fetch";
import { indexQuestions } from "../constants";

import MobileScatterplot from "./MobileScatterplot";
import useBreakpoint from "./useBreakpoint";

import "./EconomicOptimism.scss";
import "./Scatterplot.scss";

const WIDTH = 1200;
const HEIGHT = 550;
const PADDING = 150;
const DOTROWS = 8;
const R = (WIDTH - 2 * PADDING) / 5 / DOTROWS / 3;
const AXIS_STROKE = "#0087B1";
const FILL = "#F39800";
const fontSize = 12;

const xCol = "Housing";
const yCol = "Walkability";
const ttid = `tt${xCol}x${yCol}`;

export default () => {
  const data = useIndex();
  const breakpoint = useBreakpoint();
  if (!data) {
    return null;
  }

  const grouped = data.reduce((r, datum) => {
    const key = `${datum[xCol]}_${datum[yCol]}`;

    if (!r[key]) {
      r[key] = [];
    }

    r[key].push(datum);

    return r;
  }, {});

  const mobileGroup = Object.values(grouped).map(group => {
    const city = group.length === 1 ? "city" : "cities";
    return {
      Country: `${group.length} ${city}`,
      Walkability: group[0]["Walkability"] >= 3 ? "High" : "Low",
      Housing: group[0]["Housing"] >= 3 ? "High" : "Low"
    };
  });

  return breakpoint === "mobile" ? (
    <MobileScatterplot
      data={mobileGroup}
      xCol={xCol}
      yCol={yCol}
      title={"Rating"}
    />
  ) : (
    <DesktopScatterplot grouped={grouped} />
  );
};

const DesktopScatterplot = ({ grouped }) => {
  const scaleX = scaleLinear()
    .domain([1, 5])
    .range([PADDING, WIDTH - PADDING]);

  const scaleY = scaleLinear()
    .domain([1, 5])
    .range([HEIGHT - PADDING, PADDING]);

  const axisPad = fontSize * 1.5;

  return (
    <div className="Scatterplot">
      <div className="Scatterplot-x">{indexQuestions["Housing"]}</div>
      <div className="Scatterplot-y">{indexQuestions["Walkability"]}</div>
      <svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
        <line
          x1={axisPad}
          x2={WIDTH}
          y1={axisPad}
          y2={axisPad}
          stroke={AXIS_STROKE}
        />
        <text className={"ScatterPlot-label"} x={axisPad} y={fontSize}>
          Agree Less
        </text>
        <text
          className={"ScatterPlot-label"}
          x={WIDTH}
          y={fontSize}
          textAnchor={"end"}
        >
          Agree More
        </text>

        <line
          x1={axisPad}
          x2={axisPad}
          y1={axisPad}
          y2={HEIGHT}
          stroke={AXIS_STROKE}
        />
        <text
          className={"ScatterPlot-label"}
          x={-fontSize / 2}
          y={0}
          transform={`rotate(-90 ${fontSize},0)`}
          textAnchor="end"
        >
          Agree More
        </text>
        <text
          className={"ScatterPlot-label"}
          x={fontSize}
          y={HEIGHT}
          transform={`rotate(-90 ${fontSize},${HEIGHT})`}
        >
          Agree Less
        </text>

        <line
          x1={axisPad}
          y1={HEIGHT / 2}
          x2={WIDTH}
          y2={HEIGHT / 2}
          stroke={AXIS_STROKE}
        />
        <line
          x1={WIDTH / 2}
          y1={fontSize}
          x2={WIDTH / 2}
          y2={HEIGHT}
          stroke={AXIS_STROKE}
        />

        {Object.keys(grouped).map(key => {
          const [x, y] = key.split("_");
          return (
            <Group
              data={grouped[key]}
              key={key}
              cx={scaleX(x)}
              cy={scaleY(y)}
            />
          );
        })}
      </svg>
      <Tooltip id={ttid} className="Tooltip MovingMapTooltip" />
    </div>
  );
};

const Group = ({ data, cx, cy }) => {
  const ref = useRef();
  // const [nodes,setNodes] = useState([])

  useEffect(() => {
    const nodes = data.map(d => {
      return {
        ...d,
        radius: R,
        x: cx,
        y: cy
      };
    });

    const circle = select(ref.current)
      .selectAll("circle")
      .data(nodes)
      .enter()
      .append("circle")
      .attr("r", R)
      .attr("fill", FILL)
      .attr("data-tip", d => makeTooltip(d))
      .attr("data-html", true)
      .attr("data-for", ttid);

    Tooltip.rebuild();

    forceSimulation()
      .force("repel", forceManyBody().strength(-1))
      .force("attract", forceManyBody().strength(0.5))
      .force(
        "collide",
        forceCollide()
          .radius(R)
          .strength(0.5)
      )
      .nodes(nodes)
      .on("tick", tick);

    function tick(e) {
      circle
        .attr("cx", function(d) {
          return d.x;
        })
        .attr("cy", function(d) {
          return d.y;
        });
    }
  }, [data, cx, cy]);

  return <g ref={ref} />;
};

const makeTooltip = data => {
  return `
    <div className="MovingMapTooltip-inner">
      <div class="MovingMapTooltip-inner-region">${data[keys.label]}</div>
      <div class="MovingMapTooltip-inner-values">
      ${[xCol, yCol].map(col => ttValue(col, data[col])).join("")}
      </div>
    </div>
  `;
};

const ttValue = (label, value) =>
  `<div class="MovingMapTooltip-inner-total">
      <span class="value" style="font-size: 3rem;"">${value}</span>
      <span class="MovingMapTooltip-inner-list">${label}</span>
    </div>`;
