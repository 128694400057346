import React from "react"
import { Waypoint } from "react-waypoint"
import { a, useSpring } from "react-spring"
import { greens } from "../constants"
import { useCsvStore } from "Fetch";
import Loading from "./Loading"
import "./FlowChart.scss"

const WIDTH=900
const PADDING=80
const BLOCK_CALC=5

export default ({csv}) => {
  if (!csv.length) { return "No data file"}
  const data = useCsvStore(csv[0].url);

  if (!data) return <Loading />
  const sorter = data['columns'][0]
  const titles = data['columns'].slice(1,3)
  const dataObj = data.reduce((r,datum) => {
    Object.keys(datum).map((dat,ind) => {
      const key = datum[sorter]

      if (r && dat !== sorter) {
        if (!r[key]) {r[key] = []}

        r[key].push({
          total: parseInt(datum[dat]),
          title: dat
        })
      }
      return key
    })
    return r
  },{})

  return <section className="FlowChart">
    <header>
      <ul className="FlowChart-Axis--X">
        {titles.map(d =>
          <li key={d}>{d}</li>
        )}
      </ul>
    </header>
    {Object.keys(dataObj).map((datum,i) => {
      return <div className="FlowChart-Svg" key={`${datum}_${i}`}>
        <p className="FlowChart-Axis--Y">{datum}</p>
        <ChartGroup
          data={dataObj[datum]}
          title={datum}
          titles={titles}
          index={i}
        />
      </div>
    })}
  </section>
}

const ChartGroup = ({data, title, titles, index}) => {
  const blockWidth = (WIDTH/2) - (PADDING/2)
  const datum = [...data]
  const yValOffset = parseInt(datum.sort((a,b) => { return b['total'] - a['total'] })[0]['total'])
  const svgHeight = calcHeight(yValOffset,0)

  const [pathStyle,setPathStyle] = useSpring(() => ({
    opacity: 0
  }))

  return <svg viewBox={`0 0 ${WIDTH} ${svgHeight < 32 ? (svgHeight + PADDING*.3) : svgHeight}`}>
    {data.map((count,i) => {
      return  <Rects count={count} i={i} index={index} blockWidth={blockWidth} titles={titles} key={`${title}_${i}`} />
    })}
    <Waypoint onEnter={() => {
      setPathStyle({
        opacity: 0.3,
        delay: 500
      })
    }}>
    <a.path fill={greens[index+1]}
      style={pathStyle}
      d={`M ${blockWidth} 0
          L ${blockWidth + (PADDING/2)} 0
          L ${blockWidth + (PADDING/2)} ${calcHeight(data[1]['total'], 0)}
          L ${blockWidth} ${calcHeight(data[0]['total'], 0)}
          L ${blockWidth} 0`} />

    </Waypoint>
  </svg>
}

const Rects = ({count, i, index, blockWidth, titles}) => {
  const colTwoX = WIDTH/2
  const blockHeight = calcHeight(count['total'], 0)
  const column = titles.indexOf(count['title'])
  const xVal = column ? colTwoX : 0
  const yVal = 0

  let textPos = (blockHeight/2) + PADDING*.3
  let textColor = index < 5 ? '#fff' : greens[1]

  if (count['total'] < 6) {
    textPos = blockHeight + PADDING*.5
    textColor = greens[index+1]
  }

  const [rectStyle,setRectStyle] = useSpring(() => ({
    width: '0px'
  }))

  const [textStyle,setTextStyle] = useSpring(() => ({
    opacity: 0
  }))

  function enter() {
    setRectStyle({
      width: `${blockWidth}px`,
      delay: i%2 ? 550 : 0
    })

    setTextStyle({
      opacity: 1,
      delay: 500
    })
  }

  return <Waypoint onEnter={() => { enter() }}>
    <g>
      <a.rect
        fill={greens[index+1]}
        height={blockHeight}
        width={blockWidth}
        x={xVal}
        y={yVal}
        style={rectStyle} />
      <a.text
        fontSize={24}
        dy={-22}
        dominantBaseline="middle"
        textAnchor="middle"
        fill={textColor}
        x={xVal + (blockWidth/2)}
        y={textPos}
        style={textStyle}
      >{`${count['total']}%`}</a.text>
    </g>
  </Waypoint>
}

const calcHeight = (num, pad) => {
  return typeof num === 'undefined' ? null : (num * BLOCK_CALC) + pad
}
