import React, { useRef } from "react";
import { Waypoint } from "react-waypoint";
import { a, useSpring, config } from "react-spring";
import { indexcolors, greens } from "../constants";

import ComparedToText from "./ComparedToText";

const WIDTH = 350;
const HEIGHT = 350;

export const percentBlockColors = {
  lightGreen: ["#25BDA3", "#D9F0E2"],
  blue: ["#3986AD", "#C5E7EC"],
  orange: ["#E79B37", "#FAEBC4"],
  darkGreen: ["#005D4C", "#D9F0E2"],
  charcoal: ["#2B2B33", "#2B2B33"]
};

export default ({
  perc,
  copy,
  subcopy,
  color,
  type,
  total,
  text,
  percentBlockColor
}) => {
  const nodeRef = useRef();
  const isDouble = type === "double";

  return (
    <li
      ref={nodeRef}
      className={`PercentageBlock${isDouble ? "--double" : ""}`}
    >
      {isDouble ? (
        <DoubleBlock perc={perc} color={color} />
      ) : (
        <Block
          perc={perc}
          color={color}
          isDouble={isDouble}
          percentBlockColor={percentBlockColor}
        />
      )}
      <div className="PercentageBlock-text">
        {copy ? <p className="PercentageBlock-copy">{copy}</p> : null}

        {isDouble ? (
          <small className="Helper-text">{subcopy}</small>
        ) : text ? (
          <small
            className="Helper-text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ) : (
          <ComparedToText copy={`${total ? total : 100 - perc}% globally`} />
        )}
      </div>
    </li>
  );
};

const Block = ({ perc, color, isDouble, percentBlockColor }) => {
  const minPerc = isDouble ? 28 : 25;
  const colors = isDouble ? indexcolors : greens;
  const colorTint = isDouble ? `${color}_tint` : color - (color - 1);

  const copyColor = perc < minPerc ? "#000" : "#fff";
  const size = HEIGHT * (perc / 100);

  const theme = percentBlockColor
    ? percentBlockColors[percentBlockColor]
    : null;
  const outerFill = theme ? theme[1] : colors[colorTint];
  const innerFill = theme ? theme[0] : colors[color];

  const [outerStyle, setOuter] = useSpring(() => ({
    fill: outerFill,
    opacity: 0,
    config: config.stiff
  }));

  const [innerStyle, setInner] = useSpring(() => ({
    fill: innerFill,
    transformOrigin: "center",
    transform: "scale(0)",
    config: config.stiff
  }));

  const [percStyle, setPerc] = useSpring(() => ({
    fill: copyColor,
    opacity: 0,
    config: config.stiff
  }));

  function enter() {
    setOuter({
      fill: outerFill,
      opacity: 1,
      delay: 200
    });

    setInner({
      fill: innerFill,
      transform: "scale(1)",
      delay: 500
    });

    setPerc({
      opacity: 1,
      delay: 1000
    });
  }

  return (
    <Waypoint
      onEnter={() => {
        enter();
      }}
    >
      <svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
        <a.rect
          fill={outerStyle.fill}
          height={HEIGHT}
          width={WIDTH}
          style={outerStyle}
        />
        <a.rect
          fill={innerStyle.fill}
          height={size}
          width={size}
          x={WIDTH / 2 - size / 2}
          y={HEIGHT / 2 - size / 2}
          style={innerStyle}
        />
        <a.text
          className="PercentageBlock__percentage"
          x="50%"
          y={perc < minPerc ? HEIGHT / 2 - size : "50%"}
          dominantBaseline="middle"
          textAnchor="middle"
          style={percStyle}
        >
          {perc}%
        </a.text>
      </svg>
    </Waypoint>
  );
};

const DoubleBlock = ({ perc, color = [5, 1] }) => {
  return (
    <div style={{ position: "relative" }}>
      {perc.map((block, i) => (
        <Block key={i} perc={perc[i]} color={color[i]} isDouble={true} />
      ))}
      <Diff values={perc} />
    </div>
  );
};

const Diff = ({ values }) => {
  const diff = [WIDTH * (values[0] / 100), WIDTH * (values[1] / 100)];
  const difference = values[0] - values[1];

  const x1 = WIDTH - (WIDTH - diff[0]) / 2;
  const x2 = WIDTH + (WIDTH - diff[1]) / 2;

  const d = `
    M ${x1} ${(WIDTH - diff[0]) / 2} 
    L ${x1} ${x1}
    L ${x2} ${WIDTH - (WIDTH - diff[1]) / 2}
    L ${x2} ${(WIDTH - diff[1]) / 2}
    z
  `;

  const [style, set] = useSpring(() => ({
    opacity: 0
  }));

  function onEnter() {
    set({ opacity: 1, delay: 1500 });
  }

  return (
    <Waypoint onEnter={onEnter}>
      <a.svg
        viewBox={`0 0 ${WIDTH * 2} ${HEIGHT}`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          ...style
        }}
      >
        <path d={d} fill={percentBlockColors.charcoal[0]} />
        <text
          className="PercentageBlock__percentage"
          fill={"white"}
          x={x1 + (x2 - x1) / 2}
          y={HEIGHT / 2}
          textAnchor={"middle"}
          dominantBaseline={"middle"}
        >
          {`${difference}%`}
        </text>
      </a.svg>
    </Waypoint>
  );
};
