import React from "react";
import FOWCLogo from "components/FOWCLogo";
import "./Loading.scss";

// TODO add loader here
const Loading = () => {
  return (
    <div className="Loader">
      <p className="LoaderCopy">Loading</p>
    </div>
  );
};

export default Loading;

export const PageLoading = () => {
  return (
    <div className="PageLoading">
      <div>
        <FOWCLogo />
      </div>
      <div className="PageLoading-text">Loading...</div>
    </div>
  );
};
