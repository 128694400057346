import { combineReducers } from "redux";
import data from "./data.js";
import navigation from "./navigation";
import indexLayouts from "./indexLayouts.js";

export default combineReducers({
  data,
  navigation,
  indexLayouts
});
