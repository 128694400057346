import React from "react"

const colors = {
  High: "#3FA7C3",
  Low: "#F39800"
}

const RADIUS = 40

export default ({data,title,xCol,yCol}) => {
  return <div className="Scatterplot-mobile">
    <div className="Scatterplot-mobile-header">
      <h3>{title}</h3>
    </div>

    <ul className="Scatterplot-mobile-x-axis">
      <li>{xCol}</li>
      <li>{yCol}</li>
    </ul>

    <ul className="Scatterplot-mobile-data">
      {data.map((d,i) => {
        const fillX = title === "Rating" ? d[xCol] : 'High'
        const fillY = title === "Rating" ? d[yCol] : 'Low'
        return <li key={`${d['Country']}_${i}`}>
          <div className="Scatterplot-mobile-y-axis"><p>{d['Country']}</p></div>
          <div className="Scatterplot-mobile-graphs">
            <svg viewBox={`0 0 ${RADIUS*2} ${RADIUS*2}`}>
              <circle r={RADIUS} cx={RADIUS} cy={RADIUS} fill={colors[fillX]} />
              <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="#000000" fontSize={14}>{d[xCol]}</text>
            </svg>
            <svg viewBox={`0 0 ${RADIUS*2} ${RADIUS*2}`}>
              <circle r={RADIUS} cx={RADIUS} cy={RADIUS} fill={colors[fillY]} />
              <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="#000000" fontSize={14}>{d[yCol]}</text>
            </svg>
          </div>
        </li>
      })}
    </ul>
  </div>
}
