import React, { useState } from "react";
import { scaleLinear } from "d3-scale";
import { Waypoint } from "react-waypoint";
import { a, useSpring, config } from "react-spring";
import { useCsv } from "Fetch";

import MobileScatterplot from "./MobileScatterplot";
import useBreakpoint from "./useBreakpoint";
import ChartKey from "./ChartKey";

import src from "data/economicoptimism.csv";
import "./EconomicOptimism.scss";

const keys = {
  country: "Country",
  national: "Share Optomistic, National Economy",
  local: "Share Optomistic, Local Economy"
};
const colors = {
  national: "#3FA7C3",
  local: "#F39800"
};

const chartTitle = "Level of Optimisim";

const width = 1200;
const lineHeight = 30;
const r = 5;

const fontMono = {
  fontFamily: "ApercuWeWorkMono-Regular",
  fontWeight: 100
};

const fontReg = {
  fontFamily: "ApercuPro",
  fontWeight: 500
};

export default () => {
  const data = useCsv(src);
  const breakpoint = useBreakpoint();

  if (!data) {
    return null;
  }

  const grouped = data.map(d => ({
    ...d,
    [keys.national]: d[keys.national].replace("%", ""),
    [keys.local]: d[keys.local].replace("%", ""),
    [`National`]: d[keys.national],
    [`Local`]: d[keys.local]
  }));

  return breakpoint === "mobile" ? (
    <MobileScatterplot
      data={grouped}
      xCol={"National"}
      yCol={"Local"}
      title={chartTitle}
    />
  ) : (
    <DesktopScatterplot data={data} grouped={grouped} />
  );
};

const DesktopScatterplot = ({ data, grouped }) => {
  const height = (data.length + 3) * lineHeight;
  const scale = scaleLinear()
    .domain([0, 100])
    .range([30, width - 30]);

  return (
    <div>
      <ChartKey
        data={["National", "Local"]}
        colors={{ 1: colors["national"], 2: colors["local"] }}
        className="EconomicOptimism"
      />
      <svg viewBox={`0 0 ${width} ${height}`}>
        {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(d => (
          <g key={d}>
            <line
              x1={scale(d)}
              x2={scale(d)}
              y1={0}
              y2={height - lineHeight * 2}
              stroke={"#C5E7EC"}
            />
            <text
              key={d}
              x={scale(d)}
              y={height - lineHeight}
              textAnchor={"middle"}
              style={fontMono}
            >
              {d}%
            </text>
          </g>
        ))}
        {grouped.map((d, i) => {
          return (
            <ChartGroup
              height={height}
              scale={scale}
              d={d}
              i={i}
              key={d[keys.country]}
            />
          );
        })}
        <g>
          <line
            x1={scale(0)}
            x2={scale(100)}
            y1={height}
            y2={height}
            stroke={colors.national}
          />
        </g>
      </svg>
      <p className="EconomicOptimism-X-axis">{chartTitle}</p>
    </div>
  );
};

const ChartGroup = ({ scale, d, i }) => {
  const [hover, setHover] = useState(false);
  const [slide, setSlide] = useSpring(() => ({
    cx: scale(+d[keys.national]),
    config: config.stiff
  }));

  const offset = scale(+d[keys.local]) - 12 <= scale(+d[keys.national]);

  return (
    <Waypoint
      onEnter={() => {
        setSlide({
          cx: scale(+d[keys.local]),
          delay: 100
        });
      }}
    >
      <g
        transform={`translate(0 ${lineHeight * (i + 1)})`}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        className={hover ? "EconomicOptimism-reveal" : "EconomicOptimism-hide"}
      >
        <text
          x={scale(+d[keys.national]) - r * 4}
          textAnchor={"end"}
          dominantBaseline={"middle"}
          style={fontReg}
        >
          {d[keys.country]}
        </text>
        <circle
          cx={scale(+d[keys.national])}
          cy={0}
          r={r}
          fill={colors.national}
        />
        <text
          x={
            offset
              ? scale(+d[keys.national]) - r * 3
              : scale(+d[keys.national]) - r * 2
          }
          y={r * 4}
          className="EconomicOptimism-percentage"
        >{`${d[keys.national]}%`}</text>
        <line
          x1={scale(+d[keys.national])}
          y1={0}
          x2={scale(+d[keys.local])}
          y2={0}
          stroke={colors.national}
        />
        <a.circle
          style={slide}
          cx={scale(+d[keys.local])}
          cy={0}
          r={r}
          fill={colors.local}
        />
        <text
          x={
            offset
              ? scale(+d[keys.local]) + r * 2
              : scale(+d[keys.local]) - r * 2
          }
          y={r * 4}
          className="EconomicOptimism-percentage"
        >{`${d[keys.local]}%`}</text>
        {hover && (
          <text style={fontReg} x={scale(+d[keys.local]) + 3 * r} y={r}>
            {d["City"]}
          </text>
        )}
      </g>
    </Waypoint>
  );
};
