import React, { useEffect } from "react";
import PercentageBlock from "./PercentageBlock";
import NumberBlock from "./NumberBlock";
import BlockHeading from "./BlockHeading";
import ComparedToText from "./ComparedToText";
import { Block } from "../components/StackedBlock";
import CityTopChart from "../components/CityTopChart";
import useBreakpoint from "../components/useBreakpoint";
import { GlobalHeader } from "../components/GlobalHeader";
import Loading from "./Loading";
import { greens, indexcolors } from "../constants";
import { useIndex, useCsv } from "Fetch";
import GoBack from "components/GoBack";
import workCsv from "data/city-page-work.csv";
import indexCsv from "data/citypage-percent-agree.csv";
import bizCsv from "data/citypage-data.csv";
import topCharCsv from "data/topcharacteristics-percents.csv";
import topChallCsv from "data/topchallenges-percents.csv";
import "./CityPage.scss";
import "./StackedBlock.scss";
import { indexQuestions } from "constants.js";
import Tooltip from "react-tooltip";
import { decodeSlug } from "helpers";
import Missing from "components/Missing";

const npsTooltip = `We asked respondents, "On a scale of 1 to 10, how likely are you to recommend your city to a friend or professional acquaintance as a place to live and work?" Respondents are considered "City Promoters" with a Net Promoter Score (NPS) of 9 or above, "Passives" with an NPS of 7-8, and "City Detractors" with an NPS of 6 or below. `;

export default ({ match }) => {
  const name = match.params.name;
  let displayName = decodeSlug(name);
  if (displayName.toLowerCase() === "washington d.c.") {
    displayName = "Washington D.C.";
  }
  const allData = useIndex();
  const bizData = useCsv(bizCsv);
  const topChallenges = useCsv(topCharCsv);
  const topCharact = useCsv(topChallCsv);
  const indexPerc = useCsv(indexCsv);

  useEffect(() => {
    document.title = `${displayName} | Future of Work & Cities | WeWork`;
  }, [displayName]);

  const isLoading =
    !allData || !bizData || !topCharact || !topChallenges || !indexPerc;

  useEffect(() => {
    Tooltip.rebuild();
  }, [isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  const cityData = allData.filter(d => d["Region"] === displayName);
  if (cityData.length === 0) {
    return <Missing />;
  }

  const topCharactData = topCharact.filter(d => d["Region"] === displayName);
  const topChallengesData = topChallenges.filter(
    d => d["Region"] === displayName
  );
  const percAgreed = indexPerc.filter(d => d["Region"] === displayName);

  return (
    <article className="CityPage">
      <GlobalHeader />
      <div className="CityPage-subnav">
        <GoBack />
      </div>
      <div className="CityPage-inner">
        <header style={{ paddingTop: "2rem" }}>
          <h1 className="Block-header-xl">{displayName}</h1>
        </header>

        <section className="CityPage-indeces">
          <CityTopChart
            title={"City Index"}
            data={percAgreed[0]}
            axis={["Agreed more", "Agreed less"]}
            colors={indexcolors}
            tooltips={indexQuestions}
          />

          <CityTopChart
            title={"Most Important Characteristics"}
            data={topCharactData[0]}
            axis={["Ranked highest", "Ranked lowest"]}
            colors={greens}
          />

          <CityTopChart
            title={"Greatest Challenges"}
            data={topChallengesData[0]}
            axis={["Ranked highest", "Ranked lowest"]}
            colors={greens}
          />
        </section>

        <PercentageBlockRow header={`How do people feel about ${displayName}?`}>
          <NumberBlock
            copy={`${displayName} City Net Promoter Score`}
            num={parseFloat(cityData[0]["nps"]).toFixed(2)}
            color={greens[1]}
            tooltip={npsTooltip}
          />
          <PercentageBlock
            perc={Math.round(cityData[0]["consider_move"] * 100)}
            copy={`of respondents in ${displayName} say they may move in the next 2 years`}
            total={35}
            percentBlockColor={"darkGreen"}
          />
          <PercentageBlock
            perc={parseInt(cityData[0]["carbon_neutral_agree"])}
            copy={`of respondents in ${displayName} support their city taking climate action`}
            total={70}
            percentBlockColor={"darkGreen"}
          />
        </PercentageBlockRow>

        <PercentageBlockRow
          header={`What challenges do business and workers in ${displayName} face?`}
        >
          {bizData
            ? bizData.map((data, i) => {
                return (
                  <PercentageBlock
                    key={`${displayName}_${data["Question"]}`}
                    perc={data[displayName]}
                    copy={`of ${
                      i + 1 === bizData.length ? "workers" : "businesses"
                    } in ${displayName} say ${data["Question"]}`}
                    percentBlockColor={"darkGreen"}
                    total={data["Total"]}
                  />
                );
              })
            : null}
        </PercentageBlockRow>

        <StackedBlockRow
          header={`What workers in ${displayName} value most from work`}
          name={displayName}
        />
      </div>
      <Tooltip className="HowDidWeButton-tooltip" />
    </article>
  );
};

const PercentageBlockRow = ({ header, children }) => {
  return (
    <section className="DataRow">
      <BlockHeading text={header} />
      <ul className="PercentBlockGrid">{children}</ul>
    </section>
  );
};

const StackedBlockRow = ({ header, name }) => {
  const workData = useCsv(workCsv);
  const breakpoint = useBreakpoint();

  const style =
    breakpoint === "desktop"
      ? {
          margin: "1rem",
          flexWrap: "wrap"
        }
      : {
          margin: "0.5rem",
          flexWrap: "wrap"
        };

  if (!workData) return <Loading />;

  const filteredData = workData.filter(d => d["Region"] === name)[0];
  const sorted = Object.keys(filteredData).sort((a, b) => {
    return parseFloat(filteredData[b]) - parseFloat(filteredData[a]);
  });
  const vals = sorted.filter(val => ["Region", "Other"].indexOf(val) < 0);

  return filteredData ? (
    <section className="DataRow">
      <BlockHeading text={header} />
      <ul className="Flex-list StackedBlocks-small" style={style}>
        {vals.slice(0, 7).map((data, i) => {
          return (
            <li key={`${data}`} className="StackedBlocks-item">
              <Block title={data} count={filteredData[data]} index={i} />
              <ComparedToText copy={`${workData[0][data]}% globally`} />
            </li>
          );
        })}
      </ul>
    </section>
  ) : null;
};
