export const page = `
query($slug:[String]) {
  entries(section:"page",slug: $slug){
    ...on page_page_Entry  {
      title
      pageBuilder {
        ...on pageBuilder_allkeyfindings_BlockType {
          id
          typeHandle
        }
        ...on pageBuilder_intro_BlockType {
          id
          typeHandle
          conclusion
          children {
            ...on  pageBuilder_introStep_BlockType {
              pmin
              pmax
              text
            }
          }
        }
        ...on pageBuilder_stickyHeader_BlockType {
          id
          typeHandle
        }
        ...on pageBuilder_about_BlockType {
          id
          typeHandle
          explainer
          bigNumbers {
            ...on bigNumbers_TableRow {
             number
             label
             text
            }
          }
          note
          linkButton {
            ...on linkButton_BlockType {
              label
              path
            }
          }
        }
        ...on pageBuilder_keyFindingsNav_BlockType {
          id
          typeHandle
          heading
          text
        }
        ...on pageBuilder_keyFinding_BlockType {
          id
          typeHandle
          heading
          navTitle
          image {
           url
          }
         children {
            ...on pageBuilder_transition_BlockType {
              id
              typeHandle
              heading
              text
            }
            ...on pageBuilder_finding_BlockType {
              id
              typeHandle
              heading
              text
              tooltip
              dataVizEntry {
                ...on dataViz_textBox_Entry {
                  id
                  typeHandle
                  textBuilder {
                    ...on textBuilder_heading_BlockType {
                      id
                      typeHandle
                      heading
                    }
                    ...on textBuilder_text_BlockType {
                      id
                      typeHandle
                      text
                    }
                    ...on textBuilder_bigNumbers_BlockType {
                      id
                      typeHandle
                      bigNumbers {
                        ...on bigNumbers_TableRow {
                          number
                          label
                          text
                        }
                      }
                    }
                    ...on textBuilder_columns_BlockType {
                      id
                      typeHandle
                      children {
                        ...on textBuilder_column_BlockType {
                          id
                          heading
                          text
                        }
                      }
                    }
                  }
                }
                ...on dataViz_cityIndex_Entry {
                  id
                  typeHandle
                }
                ...on dataViz_cityIndexScroller_Entry {
                  id
                  typeHandle
                  cityIndexScroller {
                    ...on cityIndexScroller_step_BlockType {
                      ratio
                      ratioLabel
                      colorTheme
                      text
                      cityIndexColumn
                      tooltip
                    }
                  }
                }
                ...on dataViz_macroeconomicOptimism_Entry {
                  id
                  typeHandle
                }
                ...on dataViz_economicOptimismIncome_Entry {
                  id
                  typeHandle
                }
                ...on dataViz_percentageComparisonBlocks_Entry {
                  id
                  typeHandle
                  chartKey {
                  ...on chartKey_key_BlockType {
                    dataTitle
                    percentBlockColor
                  }
                }
                  percentageComparisonBlockBuilder {
                    ...on percentageComparisonBlockBuilder_comparison_BlockType {
                      id
                      percentageComparison {
                        ...on percentageComparison_BlockType {
                          a
                          b
                        }
                      }
                      heading
                      explainer
                    }
                  }
                }
                ...on dataViz_cityRanking_Entry {
                  id
                  typeHandle
                  heading
                  explainer
                  title
                  ratio
                  ratioLabel
                  columns {
                   column
                  }
                  csv {
                   url
                  }
                }
                ...on dataViz_percentageBlocks_Entry {
                  id
                  typeHandle
                  percentageBlockBuilder {
                    ...on percentageBlockBuilder_block_BlockType {
                      id
                      percentage
                      text
                      percentBlockColor
                    }
                  }
                }
                ...on dataViz_infrastructureMap_Entry {
                  id
                  typeHandle
                }
                ...on dataViz_movingMap_Entry{
                  id
                  typeHandle
                }
                ...on dataViz_housingWalkabilityScatterplot_Entry {
                  id
                  typeHandle
                }
                ...on dataViz_nestedBlocks_Entry {
                  id
                  typeHandle
                  nestedBlockBuilder {
                    ...on nestedBlockBuilder_block_BlockType {
                      id
                      percentage
                      heading
                    }
                  }
                  insights {
                    ...on insights_insight_BlockType {
                      id
                      heading
                      text
                    }
                  }
                }
                ...on dataViz_flowChart_Entry {
                  id
                  typeHandle
                  csv {
                    url
                  }
                }
                ...on dataViz_barGraph_Entry {
                  id
                  typeHandle
                  csv {
                    url
                  }
                }
                ...on dataViz_radialGraphs_Entry {
                  id
                  typeHandle
                }
                ...on dataViz_dotMatrix_Entry {
                  id
                  typeHandle
                  dotMatrixBuilder {
                    ...on dotMatrixBuilder_matrix_BlockType {
                      id
                      antecedant
                      consequent
                      ratioFormat
                    }
                  }
                }
                ...on dataViz_percentageComparisonCards_Entry {
                  id
                  typeHandle
                }
             }
           }
         }
        }
        ...on pageBuilder_finding_BlockType {
          id
          typeHandle
          heading
          text
          tooltip
          dataVizEntry {
            ...on dataViz_cityIndex_Entry {
              id
              typeHandle
            }
            ...on dataViz_cityIndexScroller_Entry {
              id
              typeHandle
            }
          }
        }
        ...on pageBuilder_footer_BlockType {
          id
          typeHandle
          heading
          text
        }
      }
    }
  }
}
`;

export const about = `
{
  entries(section:"page",slug:"about"){
    ...on page_page_Entry  {
      title
      pageBuilder {
        ...on pageBuilder_stickyHeader_BlockType {
          id
          typeHandle
        }
        ...on pageBuilder_about_BlockType {
          id
          typeHandle
          explainer
          bigNumbers {
            ...on bigNumbers_TableRow {
             number
             label
             text
            }
          }
          note
        }
        ...on pageBuilder_quoteGrid_BlockType {
          id
          typeHandle
          heading
          children {
            ...on pageBuilder_quote_BlockType {
              id
              typeHandle
              heading
              text
            }
          }
        }
        ...on pageBuilder_textBlock_BlockType {
          id
          typeHandle
          children {
            ...on pageBuilder_copy_BlockType {
              id
              typeHandle
              heading
              text
            }
          }
        }
        ...on pageBuilder_finding_BlockType {
          id
          typeHandle
          dataVizEntry {
            ...on dataViz_demographicsData_Entry {
              id
              typeHandle
              heading
              csv {
               url
              }
            }
          }
        }
      }
    }
  }
}
`;

const keyFindingAll = `
  ...on keyFindings_keyFinding_Entry {
    id
    heading
    navTitle
    slug
    image {
     url
    }
    seoImage {
      url
    }
    keyFindingBuilder {
      ...on keyFindingBuilder_transition_BlockType {
        id
        typeHandle
        heading
        text
        footnote
      }
      ...on keyFindingBuilder_finding_BlockType {
          id
          typeHandle
          heading
          text
          mapBlock
          tooltip
          dataVizEntry {
            ...on dataViz_textBox_Entry {
              id
              typeHandle
              textBuilder {
                ...on textBuilder_heading_BlockType {
                  id
                  typeHandle
                  heading
                }
                ...on textBuilder_text_BlockType {
                  id
                  typeHandle
                  text
                }
                ...on textBuilder_bigNumbers_BlockType {
                  id
                  typeHandle
                  bigNumbers {
                    ...on bigNumbers_TableRow {
                      number
                      label
                      text
                    }
                  }
                }
                ...on textBuilder_columns_BlockType {
                  id
                  typeHandle
                  children {
                    ...on textBuilder_column_BlockType {
                      id
                      heading
                      text
                    }
                  }
                }
              }
            }
            ...on dataViz_cityIndex_Entry {
              id
              typeHandle
            }
            ...on dataViz_cityIndexScroller_Entry {
              id
              typeHandle
              cityIndexScroller {
                ...on cityIndexScroller_step_BlockType {
                  ratio
                  ratioLabel
                  colorTheme
                  text
                  cityIndexColumn
                  tooltip
                }
              }
            }
            ...on dataViz_macroeconomicOptimism_Entry {
              id
              typeHandle
            }
            ...on dataViz_economicOptimismIncome_Entry {
              id
              typeHandle
            }
            ...on dataViz_percentageComparisonBlocks_Entry {
              id
              typeHandle
              chartKey {
                  ...on chartKey_key_BlockType {
                    dataTitle
                    percentBlockColor
                  }
                }
              percentageComparisonBlockBuilder {
                ...on percentageComparisonBlockBuilder_comparison_BlockType {
                  id
                  percentageComparison {
                    ...on percentageComparison_BlockType {
                      a
                      b
                    }
                  }
                  heading
                  explainer
                }
              }
            }
            ...on dataViz_cityRanking_Entry {
              id
              typeHandle
              title
              heading
              explainer
              ratio
              ratioLabel
              columns {
               column
              }
              csv {
               url
              }
            }
            ...on dataViz_percentageBlocks_Entry {
              id
              typeHandle
              percentageBlockBuilder {
                ...on percentageBlockBuilder_block_BlockType {
                  id
                  percentage
                  text
                  percentBlockColor
                }
              }
            }
            ...on dataViz_infrastructureMap_Entry {
              id
              typeHandle
            }
            ...on dataViz_movingMap_Entry{
              id
              typeHandle
            }
            ...on dataViz_housingWalkabilityScatterplot_Entry {
              id
              typeHandle
            }
            ...on dataViz_nestedBlocks_Entry {
              id
              typeHandle
              nestedBlockBuilder {
                ...on nestedBlockBuilder_block_BlockType {
                  id
                  percentage
                  heading
                }
              }
              insights {
                ...on insights_insight_BlockType {
                  id
                  heading
                  text
                }
              }
            }
            ...on dataViz_flowChart_Entry {
              id
              typeHandle
              csv {
                url
              }
            }
            ...on dataViz_barGraph_Entry {
              id
              typeHandle
              csv {
                url
              }
            }
            ...on dataViz_radialGraphs_Entry {
              id
              typeHandle
            }
            ...on dataViz_dotMatrix_Entry {
              id
              typeHandle
              dotMatrixBuilder {
                ...on dotMatrixBuilder_matrix_BlockType {
                  id
                  antecedant
                  consequent
                  ratioFormat
                  ratioLabel
                }
              }
            }
            ...on dataViz_percentageComparisonCards_Entry {
              id
              typeHandle
            }
            ...on dataViz_demographicsData_Entry {
              id
              typeHandle
              heading
              csv {
               url
              }
            }
            ...on dataViz_radialGraphs_Entry {
              id
              typeHandle
            }
         }
         children {
           ...on keyFindingBuilder_footnote_BlockType {
              id
              typeHandle
              footnote
           }
         }
      }
    }
  }
`;

export const keyFinding = `
  query($slug:[String]) {
  entries(section:"keyFindings",slug: $slug) {
    ${keyFindingAll}
  }
}
`;

export const keyFindingsNav = `
  query {
    entries(section:"keyFindings") {
      ...on keyFindings_keyFinding_Entry {
        id
        slug
        heading
        navTitle
        image {
         url
        }
      }
    }
  }
`;

export const allKeyFindings = `
  query {
    entries(section:"keyFindings") {
      ${keyFindingAll}
    }
  }
`;
