import React from "react";
import { useSpring, a, config } from "react-spring";
import { Waypoint } from "react-waypoint";

const aIn = {
  opacity: 1
};
const aOut = {
  opacity: 0
};

const FadeIn = props => {
  const [style, set] = useSpring(() => ({
    from: aOut,
    config: config.stiff
  }));
  return (
    <Waypoint
      onEnter={() => {
        set(aIn);
      }}
      onLeave={() => {
        set(aOut);
      }}
      bottomOffset={window.innerHeight / 10}
    >
      <a.div style={style}>{props.children}</a.div>
    </Waypoint>
  );
};

export default FadeIn;
