import React from "react";

import useWindowSize from "./hooks/useWindowSize";
import usePageYOffset from "./hooks/usePageYOffset";

import "./styles.scss";

import CityBackground from "./backgrounds/city";
import WaveBackground from "./backgrounds/wave";
import ConclusionBackground from "./backgrounds/conclusion";
import StickyHeader from "./sticky-header";
import ScrollLayout from "./models/scroll-layout";
import FOWCLogo from "components/FOWCLogo";

const DEBUG = false;

const lossyRound = num => Math.round(num * 1000) / 1000;

const useProgress = function(contentTop) {
  const { height } = useWindowSize();
  const yOverflow = Math.max(contentTop - height, 0);
  const model = React.useMemo(() => new ScrollLayout(height, yOverflow), [
    height,
    yOverflow
  ]);

  // Assumes this component is the top of the page
  const yOffset = usePageYOffset();
  const { step, progress } = model.calculateProgress(yOffset);

  return {
    step,
    progress: lossyRound(progress),
    yOffset,
    height,
    model,
    yOverflow
  };
};

export default function Intro({ children, conclusion }) {
  const sectionRef = React.useRef(null);
  const { step, progress, height, model, yOverflow } = useProgress(
    sectionRef.current ? sectionRef.current.offsetTop : 0
  );

  return (
    <div
      id={"Introduction"}
      className="intro-container"
      style={{ height: model.totalHeight() }}
    >
      {DEBUG && (
        <div className="debug">
          {step} {progress}
        </div>
      )}

      <div className="content">
        <FOWCLogo />
        <StickyHeader data={children} step={step} progress={progress} />

        <section
          ref={sectionRef}
          style={{
            height: `${model.totalHeight() - height - yOverflow}px`
          }}
        ></section>
      </div>

      <div className="stage">
        <div className="center-wrapper">
          <CityBackground step={step} progress={progress} />
          <ConclusionBackground step={step} text={conclusion} />
          <WaveBackground
            step={step}
            progress={progress}
            height={model.layouts[4].h}
          />
        </div>
      </div>
    </div>
  );
}
