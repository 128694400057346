import { useState, useEffect } from "react";

import innerHeight from "ios-inner-height";

function getSize() {
  return {
    width: window.innerWidth,
    height: innerHeight()
  };
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
