import React from "react";
import { Waypoint } from "react-waypoint";
import { a, useSpring, config } from "react-spring";
import { greens } from "../constants";
import useBreakpoint from "components/useBreakpoint";
import Footnote from "components/Footnote";

import "./NestedBlock.scss";

const HEIGHT = 300;
const WIDTH = 300;
const PADDING = 5;
const WHITE = "#FFFFFF";
const BLACK = "#000000";

// const defaultData = [
//   {
//     title: "Global Greenhouse Emissions",
//     percentage: 100
//   },
//   {
//     title: "City share of global greenhouse gas emissions",
//     percentage: 80
//   },
//   {
//     title: "Built environment and transportation share of city greenhouse gas emissions",
//     percentage: 56
//   }
// ]

export default ({ nestedBlockBuilder: data, insights }) => {
  return (
    <div className="NestedBlock">
      {insights && (
        <div className="NestedBlock-insights">
          {insights.map(({ heading, text, id }, i) => {
            const colorNum = Object.keys(greens).length - (1 + i) * data.length;
            return (
              <div className="NestedBlock-insights-item" key={id}>
                <div
                  className="NestedBlock-insights-item-heading"
                  style={{ color: greens[colorNum] }}
                >
                  {heading}
                </div>
                <div
                  className="NestedBlock-insights-item-explainer"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className="NestedBlock-visualization">
        <svg
          viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
          preserveAspectRatio={"xMidYMid meet"}
          className="NestedBlock"
        >
          {data.map((datum, i) => {
            const colorNum = Object.keys(greens).length - i * data.length;
            return (
              <Block
                key={datum.id}
                color={greens[colorNum]}
                value={datum.percentage}
                title={datum.heading}
              />
            );
          })}
        </svg>
        <Footnote
          footnote={
            <div>
              3{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://siteresources.worldbank.org/INTUWM/Resources/340232-1205330656272/4768406-1291309208465/PartIII.pdf"
              >
                World Bank, Cities' Contribution to Climate Change
              </a>{" "}
              page 2.
            </div>
          }
        />
      </div>
    </div>
  );
};

const Block = ({ value, title, color }) => {
  const blockHeight = (value / 100) * HEIGHT;
  const blockWidth = (value / 100) * WIDTH;

  const [blockStyle, setBlockStyle] = useSpring(() => ({
    height: "0px",
    width: "0px",
    opacity: 0,
    config: config.stiff
  }));

  const [valueStyle, setValue] = useSpring(() => ({
    opacity: 0
  }));

  function enter() {
    setBlockStyle({
      height: `${blockHeight}px`,
      width: `${blockWidth}px`,
      opacity: 1,
      transformOrigin: "bottom",
      delay: 100
    });

    setValue({
      opacity: 1,
      delay: 200
    });
  }

  const colorInd = Object.keys(greens).find(key => greens[key] === color);

  return (
    <Waypoint
      onEnter={() => {
        enter();
      }}
    >
      <g>
        <a.rect
          x={0}
          y={HEIGHT - blockHeight}
          fill={color}
          style={blockStyle}
        />
        <a.text
          fill={colorInd > 3 ? BLACK : WHITE}
          x={blockWidth}
          y={HEIGHT - blockHeight}
          dx={-40}
          dy={20}
          className="NestedBlock-number"
          style={valueStyle}
        >
          {value}%
        </a.text>
        <a.text
          fill={colorInd > 3 ? BLACK : WHITE}
          x={PADDING}
          y={HEIGHT - blockHeight}
          width={blockWidth - (40 + PADDING)}
          className="NestedBlock-label"
          style={valueStyle}
        >
          <BlockLabel title={title} />
        </a.text>
      </g>
    </Waypoint>
  );
};

const BlockLabel = ({ title }) => {
  const breakpoint = useBreakpoint();
  const fontSize = breakpoint === "desktop" ? 6 : 9;
  const breakLength =
    breakpoint === "desktop"
      ? title.match(/.{1,40}(\s|$)/g)
      : title.match(/.{1,30}(\s|$)/g);

  return title.length < 50 ? (
    <tspan x={PADDING} fontSize={fontSize} dy={fontSize + PADDING}>
      {title}
    </tspan>
  ) : (
    breakLength.map((d, i) => (
      <tspan
        x={PADDING}
        fontSize={fontSize}
        dy={i === 0 ? fontSize + PADDING : fontSize + 1}
        key={d}
      >
        {d}
      </tspan>
    ))
  );
};
