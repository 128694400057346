import React from "react";
import PercentageBlock from "components/PercentageBlock";
import ChartKey from "components/ChartKey";
import "./PercentageBlock.scss";

const PercentageBlockGrid = props => {
  const { type, items, chartKey } = props;
  return (
    <div className={`PercentBlockGrid`}>
      {chartKey ? (
        <ChartKey
          data={chartKey.map(d => d.dataTitle)}
          colors={chartKey.map(d => d.percentBlockColor)}
          className="PercentageBlock-ChartKey"
        />
      ) : null}
      <ul
        className={`PercentBlockGrid__items PercentBlockGrid__items-count--${items.length}`}
      >
        {items.map(
          ({
            id,
            heading,
            explainer,
            percentage,
            percentageComparison,
            percentBlockColor,
            text
          }) => {
            return (
              <PercentageBlock
                perc={
                  percentage
                    ? percentage
                    : Object.values(percentageComparison[0])
                }
                copy={heading}
                subcopy={explainer}
                text={text}
                type={type}
                key={id}
                percentBlockColor={percentBlockColor}
              />
            );
          }
        )}
      </ul>
    </div>
  );
};

export const PercentageBlocks = props => (
  <PercentageBlockGrid
    type="single"
    items={props.percentageBlockBuilder}
    {...props}
  />
);
export const PercentageComparisonBlocks = props => (
  <PercentageBlockGrid
    type={"double"}
    items={props.percentageComparisonBlockBuilder}
    {...props}
  />
);
