import React, { useState } from "react";
import { scaleLinear } from "d3-scale";
import { max } from "d3-array";
import { Waypoint } from "react-waypoint";
import { animated, useSpring, config } from "react-spring";
import "./PercentComparisonCards.scss";
import { greens } from "../constants";
import useBreakpoint from "components/useBreakpoint";

import ChartKey from "components/ChartKey";

const data = [
  {
    value: 62,
    a: 65,
    b: 59,
    label: "Health Benefits"
  },
  {
    value: 49,
    a: 53,
    b: 46,
    label: "Retirement Benefits"
  },
  {
    value: 40,
    a: 44,
    b: 37,
    label: "Parental Benefits"
  }
];

const colors = [
  [greens[2], "white"],
  [greens[5], "white"],
  [greens[7], greens[2]]
];

const width = 200;
const cardWidth = width * 0.6;
const height = 200;

export default () => {
  const breakpoint = useBreakpoint();

  const svgHeight = breakpoint === "desktop" ? height : 250;

  const scale = scaleLinear()
    .domain([0, 100])
    .range([0, svgHeight]);

  return (
    <div>
      <ChartKey
        data={["Women", "Overall", "Men"]}
        colors={{ 1: colors[2][0], 2: colors[1][0], 3: colors[0][0] }}
        className={"PercentComparisonCards-ChartKey"}
      />
      <div className={"PercentComparisonCards"}>
        {data.map(d => (
          <Comparison key={d.label} height={scale(d.value)} {...d} />
        ))}
      </div>
    </div>
  );
};

const Comparison = ({ a, b, value, height, label }) => {
  const scale = scaleLinear()
    .domain([0, max([a, b])])
    .range([0, height]);

  const [active, setActive] = useState(false);

  function enter() {
    setActive(true);
  }

  return (
    <div>
      <Waypoint
        onEnter={() => {
          enter();
        }}
      >
        <svg viewBox={`0 0 ${width} ${height}`}>
          <Card
            scale={scale}
            value={a}
            height={height}
            active={active}
            offset={width * 0.4}
            colors={colors[0]}
          />
          <Card
            scale={scale}
            value={value}
            height={height}
            active={active}
            offset={width * 0.2}
            colors={colors[1]}
          />
          <Card
            scale={scale}
            value={b}
            height={height}
            active={active}
            offset={0}
            colors={colors[2]}
          />
        </svg>
      </Waypoint>
      <div className="PercentComparisonCards-label">{label}</div>
    </div>
  );
};

const before = {
  transform: "scaleY(0)",
  transformOrigin: "bottom",
  config: config.stiff
};

const after = {
  transform: "scaleY(1)",
  delay: 75
};

const Card = ({ scale, value, active, height, offset, colors }) => {
  const [cardHeight, setCardHeight] = useSpring(() => before);

  setCardHeight(active ? after : before);

  return (
    <g>
      <animated.rect
        x={offset}
        y={height - scale(value)}
        width={cardWidth}
        height={scale(value)}
        style={cardHeight}
        fill={colors[0]}
      />
      <text
        className="PercentComparisonCards-value"
        x={offset + cardWidth}
        dx={-5}
        dy={20}
        y={height - scale(value)}
        textAnchor={"end"}
        fill={colors[1]}
      >
        {value}%
      </text>
    </g>
  );
};
