import React from "react";

import { Transition } from "react-transition-group";

import { stepProgress } from "./utils/step";

import "./styles.scss";

const Section = ({ style, children }) => {
  return (
    <div className="sticky-header-section" style={style}>
      {children}
    </div>
  );
};

const timeout = 500;

// const data = [
//   {
//     pMin: 0,
//     pMax: 0.5,
//     text: `<h2>
//       Three major trends will define the 21st century: urbanization,
//       a technological revolution poised to disrupt millions of
//       people’s jobs, and climate change
//     </h2>`
//   },
//   {
//     pMin: 0.5,
//     pMax: 1.3,
//     text: `
//       <h2>
//         For the first time in history, the majority of humanity is
//         living in cities big and small, and by 2030, roughly
//         two-thirds of the world’s population will live in urban areas.
//       </h2>
//     `
//   }
// ];

export default function StickyHeader({ data, step, progress }) {
  const step1Progress = stepProgress(1, step, progress);
  const step2Progress = stepProgress(2, step, progress);
  const step3Progress = stepProgress(3, step, progress);
  const step4Progress = stepProgress(4, step, progress);
  const step5Progress = stepProgress(5, step, progress);

  const p =
    step1Progress +
    step2Progress +
    step3Progress +
    step4Progress +
    step5Progress;

  const transitionStyles = {
    entered: { opacity: 1 },
    exiting: { opacity: 0, transform: "translateY(20px)" }
  };
  return (
    <div className="sticky-header">
      <header>The Future of Work and Cities</header>
      <article>
        {data.map(({ pmin, pmax, text }) => (
          <Transition
            key={pmin}
            in={p >= pmin && p <= pmax}
            timeout={timeout}
            unmountOnExit
          >
            {state => {
              return (
                <Section
                  style={{
                    ...transitionStyles[state]
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                </Section>
              );
            }}
          </Transition>
        ))}

        {/* <Transition in={p >= 0.5 && p < 1.3} timeout={timeout} unmountOnExit>
          {state => {
            return (
              <Section
                style={{
                  ...transitionStyles[state]
                }}
              >
                
              </Section>
            );
          }}
        </Transition>

        <Transition in={p >= 1.3 && p < 2.2} timeout={timeout} unmountOnExit>
          {state => {
            return (
              <Section
                style={{
                  ...transitionStyles[state]
                }}
              >
                <div>
                  <h2>
                    Meanwhile, a technological revolution is testing our social
                    fabric: by 2030, 400-800 million people globally may need to
                    find new jobs.
                    <sup>1</sup>
                  </h2>
                  <aside>
                    <sup>1</sup> According to McKinsey, automation alone may
                    displace up to 30% of the American workforce
                  </aside>
                </div>
              </Section>
            );
          }}
        </Transition>

        <Transition in={p >= 2.2 && p < 3.3} timeout={timeout} unmountOnExit>
          {state => {
            return (
              <Section
                style={{
                  ...transitionStyles[state]
                }}
              >
                <h2>
                  While these shifts impact the global workforce, global
                  temperatures, carbon emissions, and sea levels are rising,
                  making the impacts of climate change an imminent threat for
                  people in cities around the world.
                </h2>
              </Section>
            );
          }}
        </Transition>

        <Transition in={p >= 3.6 && p < 4.3} timeout={timeout} unmountOnExit>
          {state => {
            return (
              <Section
                style={{
                  ...transitionStyles[state]
                }}
              >
                <h2 style={{ color: "white" }}>
                  The question of how we will shape the future of work and
                  cities to progress towards a new economy - that uplifts all
                  people and all communities - is perhaps the most important
                  question of our time.
                </h2>
              </Section>
            );
          }}
        </Transition> */}
      </article>
    </div>
  );
}
