import React from "react";
import BlockHeading from "../components/BlockHeading";
import Text from "./Text";
import "./QuoteGrid.scss";

export default ({ heading, children }) => {
  return <section className="QuoteGrid">
    <BlockHeading text={heading} />
    <ul className="QuoteGrid-list">{children.map(block =>
      <Quote key={block.id} {...block} />
    )}</ul>
  </section>
}


const Quote = ({ heading, text }) => {
  return <li className="Quote">
    <h3 className="Quote-heading">{heading}</h3>
    <div className="Quote-text"><Text text={text} /></div>
  </li>
}
