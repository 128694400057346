import React from "react";
import { scaleLinear } from "d3-scale";

const width = 100
const height = 20

export default ({ fill, backgroundFill, value, domain }) => {
  const scale = scaleLinear()
    .domain(domain)
    .range([0,width])

  return <svg viewBox={`0 0 ${width} ${height}`} preserveAspectRatio={"none"} style={{
    height: `${height}px`,
    width: "100%",
    display: "block"
  }}>
    <rect x={0} y={0} width={width} height={height} fill={backgroundFill} />
    <rect x={0} y={0} width={scale(value)} height={height} fill={fill} />
  </svg>
}
