import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Tooltip from "react-tooltip";
import { keyFindingsNav as query } from "queries";
import { useApi } from "Fetch";
import Loading from "../components/Loading";
import GrowIn from "GrowIn";
import Finding from "blocks/Finding";

const selectFindings = (data, current) =>
  data.reduce((r, d) => {
    if (d.navTitle) {
      r.push({
        id: d.id,
        label: d.navTitle,
        copy: d.heading,
        image: d.image[0],
        slug: d.slug,
        href: `/${d.slug}`,
        isCurrent: current === d.slug
      });
    }
    return r;
  }, []);

export default ({ current, heading, text }) => {
  return (
    <Finding
      heading={heading}
      text={text}
      stepChildren={
        <>
          <KeyFindingLinks current={current} />
          <Tooltip
            id={"tooltip-keyfindings"}
            className={"KeyFindNavItem-tooltip"}
            multiline={true}
            type={"light"}
            place={"bottom"}
          />
        </>
      }
    />
  );
  // return (
  //   <FadeIn>
  //     <div id="KeyFindings" className="Finding">
  //       <div className="Finding-inner">
  //         <div className="Finding-header">
  //           <h2 className="Finding-header-heading">{heading}</h2>
  //           <div
  //             className="Finding-header-text"
  //             dangerouslySetInnerHTML={{ __html: text }}
  //           />
  //         </div>
  //       </div>
  //       <KeyFindingLinks current={current} />

  //       <Tooltip
  //         id={"tooltip-keyfindings"}
  //         className={"KeyFindNavItem-tooltip"}
  //         multiline={true}
  //         type={"light"}
  //         place={"bottom"}
  //       />
  //     </div>
  //   </FadeIn>
  // );
};

export const KeyFindingLinks = ({ current, hash }) => {
  const res = useApi(query);

  if (!res) return <Loading />;
  const findings = selectFindings(res.data.entries, current);

  return (
    <>
      <ul className="KeyFindingsNav">
        {findings.map((finding, i) => {
          return <KeyFindNavItem key={finding.id} hash={hash} {...finding} />;
        })}
      </ul>
      {/* <Footer /> */}
    </>
  );
};

export const NextFinding = ({ current }) => {
  const res = useApi(query);
  if (!res) return null;
  const findings = selectFindings(res.data.entries, current);
  const nextIndex = findings.findIndex(d => d.isCurrent) + 1;

  if (!findings[nextIndex]) {
    return null;
  }

  return (
    <h3 className="KeyFindingsNav-more KeyFindNavItem-label">
      Explore More Key Findings
    </h3>
  );
};

const KeyFindNavItem = ({
  label,
  slug,
  copy,
  image,
  isCurrent,
  hash = true
}) => {
  const tipRef = useRef();

  useEffect(() => {
    Tooltip.rebuild();
  }, []);

  const NavLink = hash
    ? ({ children }) => <HashLink to={`#${slug}`}>{children}</HashLink>
    : ({ children }) => <Link to={`/${slug}`}>{children}</Link>;

  return (
    <li
      data-tip={copy}
      data-for={"tooltip-keyfindings"}
      className="KeyFindNavItem"
      style={{
        border: isCurrent ? "1px solid #0087b1" : undefined
      }}
      ref={tipRef}
    >
      <GrowIn reverse={false}>
        <NavLink>
          {image ? (
            <img
              src={image.url}
              className={"KeyFindNavItem-image"}
              alt={"Abstract drawing representing " + label}
            />
          ) : null}
          <h3 className="KeyFindNavItem-label">{label}</h3>
        </NavLink>
      </GrowIn>
    </li>
  );
};
