import React from "react"
import { greens } from "../constants";
import "./BigNumbers.scss";

export default ({ items }) => {
  return <div className="BigNumbers">
    {items.map(({ number, label, text }) => <div key={number + label} className="BigNumber">
      {number && <div className="BigNumbers-number" style={{color: greens[5]}}>{number}</div>}
      {label && <div className="BigNumbers-label">{label}</div>}
      {text && <div className="Bignumbers-text">{text}</div>}
    </div>)}
  </div>
}
