import React, { useState } from "react";
import { ItemContent } from "./CityIndexScroller";
import Bar from "./Bar";
import { indexcolors } from "../constants";
import keys from "keys";
import CityList from "./CityList";

export default ({ data, items }) => {
  return (
    <div>
      {items.map(({ cityIndexColumn: col, ...rest }) => {
        const agreeCities = determineAgree(data, col);
        return (
          <div key={col} className="CityIndex-mobile">
            <Bar
              domain={[0, 50]}
              value={agreeCities.length}
              fill={indexcolors[5]}
              backgroundFill={indexcolors[1]}
            />
            <ItemContent {...rest} />
            <ViewCities items={agreeCities} />
          </div>
        );
      })}
    </div>
  );
};

const determineAgree = (data, col) => {
  return data.reduce((r, d) => {
    if ([5, 4].includes(+d[col])) {
      r.push(d[keys.label]);
    }
    return r;
  }, []);
};

const ViewCities = ({ items }) => {
  const [open, set] = useState(false);

  return (
    <div>
      <div className="CityIndex-buttons">
        <button
          className={`CityIndexMobile-button${open ? " is-open" : ""}`}
          onClick={() => set(!open)}
        >
          View Cities
          <RightArrow />
        </button>
      </div>
      <div>{open && <CityList items={items} fill={indexcolors[5]} />}</div>
    </div>
  );
};

const RightArrow = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 8 13"
      enableBackground="new 0 0 8 13"
    >
      <g id="KF1-Mobile" transform="translate(-121.000000, -1689.000000)">
        <g id="Group-2" transform="translate(0.000000, 529.000000)">
          <g id="Group-3" transform="translate(16.000000, 1152.000000)">
            <polygon
              fill="#0087B1"
              points="107.2,20.2 105.8,18.8 110.1,14.5 105.8,10.2 107.2,8.8 112.9,14.5        "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
