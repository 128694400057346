import React from "react";
import Builder from "../components/Builder";
import { HowDidWe } from "components/HowDidWeButton";
// import FadeIn from "FadeIn";

const Finding = ({
  heading,
  text,
  mapBlock,
  dataVizEntry,
  children,
  stepChildren,
  tooltip
}) => {
  const parentId =
    dataVizEntry &&
    dataVizEntry.filter(entry => {
      return entry.typeHandle === "cityIndex";
    });

  return (
    <section
      className={`Finding${mapBlock ? " Finding--map-block" : ""}`}
      id={parentId ? "CityIndex-Section" : ""}
    >
      <div className="Finding-inner">
        {heading || text ? (
          <div className="Finding-header">
            <h2 className="Finding-header-heading">
              <div className="Finding-header-heading-text">{heading}</div>
              {tooltip && <HowDidWe text={tooltip} />}
            </h2>
            <div className="Finding-header-textwrap">
              <div
                className="Finding-header-text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          </div>
        ) : null}
        {dataVizEntry &&
          dataVizEntry.map(entry => {
            return <Builder key={entry.id} blocks={[entry]} />;
          })}
        {children &&
          children.map(note => {
            return <Builder key={note.id} blocks={[note]} />;
          })}
        {stepChildren}
      </div>
    </section>
  );
};

const AnimatedFinding = props => <Finding {...props} />;

export default AnimatedFinding;
