import React from "react";
import { page as query } from "queries";
import { useApi } from "Fetch"
import Builder from "components/Builder";
import FOWCLogo from "components/FOWCLogo";
import "./Footer.scss";

export default () => {
  const res = useApi(query, { slug: "footer" })
  if (!res) { return null }

  return <Builder blocks={res.data.entries[0].pageBuilder} />
}

export const FooterBlock = ({ heading, text }) => {
  return <footer className="Footer AboutModule">
    <div className="Footer-inner">
      <div className="Footer-heading">{heading}</div>
      <div className="Footer-text" dangerouslySetInnerHTML={{ __html: text }} />
      <div className="Footer-logo"><FOWCLogo /></div>
    </div>
  </footer>
}