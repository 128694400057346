import React, { useState } from "react";
import coords from "data/coords.json";
import geography from "data/land-110m.json";
import { useSpring, a } from "react-spring";
import { indexcolors, greens } from "../constants";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography
} from "react-simple-maps";
import Tooltip from "react-tooltip";
import "./MovingMap.scss";
import keys from "keys";
import { useIndex } from "Fetch";
import AsyncComp from "AsyncComp";
import ChartKey from "./ChartKey";
import useBreakpoint from "./useBreakpoint";

const markerRadius = 5;
const indexNeutralOverride = "#eee0d6";

const allMarkers = Object.values(coords).reduce((r, d) => {
  r[d.region] = {
    region: d.region,
    coordinates: [d.coords.lng, d.coords.lat]
  };
  return r;
}, {});

const fill = {
  from: greens[6],
  to: greens[3],
  land: "#fff",
  water: "#C5E7EC"
};

const InfrastructureMap = () => {
  const data = useIndex();
  if (!data) {
    return null;
  }
  const markers = data.map(d => ({
    region: d[keys.label],
    coordinates: allMarkers[d[keys.label]].coordinates,
    value: +d["Infrastructure"]
  }));
  return (
    <div>
      <Map markers={markers} data={data} />
    </div>
  );
};

const Map = ({ markers }) => {
  const footnote =
    "2 Harvard Business School Working Paper: Between Home and Work: Commuting as an Opportunity for Role Transitions";

  const [currentMarker, setCurrentMarker] = useState();
  const Zoom = ZoomableGroup;
  const breakpoint = useBreakpoint();

  return (
    <div className="MovingMap">
      <ChartKey
        data={["High Score (4-5)", "Average Score (3)", "Low Score (1-2)"]}
        colors={{
          1: indexcolors[5],
          2: indexcolors[3],
          3: indexcolors[1]
        }}
        className="MovingMap-ChartKey"
      />
      <ComposableMap
        projectionConfig={{
          scale: 170
        }}
        style={{
          width: "100%",
          height: "100%",
          display: "block"
        }}
      >
        <Zoom disablePanning={breakpoint !== "desktop"}>
          <rect
            x={0}
            y={0}
            width={800}
            height={450}
            fill={fill.water}
            onMouseEnter={() => setCurrentMarker(null)}
          />
          <Geographies geography={geography} disableOptimization>
            {(geographies, projection) => {
              let geos = geographies.map((geography, i) => {
                return (
                  <Geography
                    key={geography.properties.ISO_A3 + i}
                    geography={geography}
                    projection={projection}
                    style={{
                      default: {
                        fill: fill.land,
                        pointerEvents: "none"
                      }
                    }}
                  />
                );
              });
              return geos;
            }}
          </Geographies>

          <Geographies geography={markers} key={"geo" + currentMarker}>
            {(markers, projection) => {
              return markers.map(marker => {
                const coords = projection(marker.coordinates);
                const color = marker.value === 3 ? 3 : marker.value < 4 ? 1 : 5;
                if (!coords) {
                  return null;
                }
                const [x, y] = coords;

                return (
                  <AnimatedCircle
                    key={marker.region + "from" + currentMarker}
                    cx={x}
                    cy={y}
                    r={markerRadius}
                    data-tip={makeTooltip(marker)}
                    data-for={"InfrastructureMap"}
                    data-html
                    style={{
                      mixBlendMode: "multiply"
                    }}
                    fill={
                      color === 3 ? indexNeutralOverride : indexcolors[color]
                    }
                    onMouseEnter={() => {
                      setCurrentMarker(marker.region);
                    }}
                  />
                );
              });
            }}
          </Geographies>
        </Zoom>
      </ComposableMap>
      <Tooltip
        className="Tooltip MovingMapTooltip"
        id={"InfrastructureMap"}
        place="bottom"
      />
      <small className="MovingMap-footnote">{footnote}</small>
    </div>
  );
};

const AsyncInfrastructureMap = props => (
  <AsyncComp>
    <InfrastructureMap {...props} />
  </AsyncComp>
);

export default AsyncInfrastructureMap;

const makeTooltip = marker => {
  const { region, value } = marker;

  const color = value < 4 ? 1 : 5;

  return `<div class="MovingMapTooltip-inner">
    <div class="MovingMapTooltip-inner-region">${region}</div>
    <div class="MovingMapTooltip-inner-total">
      <span class="value" style="color: ${indexcolors[color]};"">${value}</span>
      <span class="MovingMapTooltip-inner-list">Infrastructure</span>
    </div>
  </div>`;
};

const AnimatedCircle = ({ r, ...rest }) => {
  const props = useSpring({
    from: {
      r: 0
    },
    to: {
      r
    }
  });

  return <a.circle r={props.r} {...rest} />;
};
