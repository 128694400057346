import React from "react";
import { greens } from "../constants";
import { Waypoint } from "react-waypoint";
import { a, useSpring, config } from "react-spring";

import "./StackedBlock.scss";

const WIDTH = 160;
const HEIGHT = 160;

export default ({ data }) => {
  if (!data) return null;

  const style = {
    flexWrap: "wrap"
  };

  return (
    <ul className="Flex-list" style={style}>
      {data.map((d, i) => {
        return (
          <li key={`${d["Value"]}_${i}`} className="StackedBlocks-items">
            <Block title={d["Value"]} count={d["Total"]} index={i} />
          </li>
        );
      })}
    </ul>
  );
};

export const Block = ({ title, count, index, total }) => {
  const fillHeight = total ? Math.round((count / total) * 100) : count;

  const [rectStyle, setRectStyle] = useSpring(() => ({
    height: "0px",
    transformOrigin: "center",
    transform: "rotate(180deg)",
    y: 0,
    config: config.stiff
  }));

  function enter() {
    setRectStyle({
      height: `${HEIGHT * (fillHeight / 100)}px`,
      delay: 100 * index
    });
  }

  return (
    <Waypoint
      onEnter={() => {
        enter();
      }}
      key={`${title}_${fillHeight}`}
    >
      <div className="StackedBlock">
        <svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
          <rect fill={greens[7]} height={HEIGHT} width={WIDTH} />
          <a.rect
            fill={greens[5]}
            height={HEIGHT * (fillHeight / 100)}
            width={WIDTH}
            y={HEIGHT - fillHeight * 1.6}
            style={rectStyle}
          />
        </svg>
        <div className="StackedBlock-label">
          <div className="StackedBlock-label-heading">{title}</div>
          <div className="StackedBlock-label-value">{`${fillHeight}%`}</div>
        </div>
      </div>
    </Waypoint>
  );
};
