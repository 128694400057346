import React from "react";
import BigNumbers from "components/BigNumbers";
import { Link } from "react-router-dom"
import "./AboutModule.scss";
import WeWorkFOWLogoPNG from "images/wework-fow.png";
import FadeIn from "FadeIn";

export default ({ explainer, bigNumbers, note, linkButton }) => {

  return <FadeIn>
    <section className="AboutModule">
      <img
        srcSet={`${WeWorkFOWLogoPNG} 2x`}
        className="wework-fow-logo"
        alt="WeWork Future of Work logo"
      />
      <div className="AboutModule-explainer">{explainer}</div>
      <BigNumbers items={bigNumbers} />
      <div className="AboutModule-note">{note}</div>
      {linkButton ? <Link className="LinkButton" to={linkButton[0].path}>{linkButton[0].label}</Link> : null}
    </section>
  </FadeIn>
}
