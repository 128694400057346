import React from "react";

import "../styles.scss";

export default function ConclusionBackground({ step, text }) {
  return (
    <div
      className="conclusion-container"
      style={{ opacity: step >= 5 ? 1 : 0 }}
    >
      <div
        className="conclusion-text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}
