const defaultState = {
  subtitle: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "SET_SUBTITLE":
      return {
        ...state,
        subtitle: action.value
      };

    default:
      return state;
  }
};
