import React from "react";

import heading from "./Heading.js"
import text from "./Text.js";
import BigNumbers from "components/BigNumbers"
import staticPlaceholder from "./StaticPlaceholder.js"
import intro from "components/Intro";
import keyFinding from "./KeyFinding"
import about from "./About";
import keyFindingsNav from "./KeyFindingsNav";
import finding from "./Finding";
import quoteGrid from "./QuoteGrid";
import textBlock from "./TextBlock";
import demographicsData from "./DemographicsData";
import { CityIndexInteractive as cityIndex, CityIndexScroller as cityIndexScroller } from "components/CityIndex";
import economicOptimismIncome from "../components/EconomicOptimismIncome";
import { PercentageComparisonBlocks as percentageComparisonBlocks } from "../components/PercentageBlockGrid";
import cityRanking from "components/Comparison";
import movingMap from "components/MovingMap";
import infrastructureMap from "components/InfrastructureMap";
import housingWalkabilityScatterplot from "components/HousingWalkability"
import { PercentageBlocks as percentageBlocks } from "components/PercentageBlockGrid"
import nestedBlocks from "components/NestedBlock";
import flowChart from "components/FlowChart"
import barGraph from "components/BarGraph"
import radialGraphs from "components/RadialGraph";
import transition from "components/Transition";
import textBox from "components/TextBox";
import macroeconomicOptimism from "components/EconOptimism"
import percentageComparisonCards from "components/PercentComparisonCards";
import dotMatrix from "components/DotMatrix";
import { GlobalHeader as stickyHeader } from "components/GlobalHeader"
import columns from "components/Columns";
import { FooterBlock as footer } from "./Footer";
import allkeyfindings from "components/AllKeyFindings";
import footnote from "components/Footnote"

const bigNumbers = ({ bigNumbers }) => {
  return <BigNumbers items={bigNumbers} />
}

export default {
  heading,
  text,
  bigNumbers,
  staticPlaceholder,
  intro,
  keyFinding,
  about,
  keyFindingsNav,
  finding,
  quoteGrid,
  textBlock,
  demographicsData,
  cityIndex,
  cityIndexScroller,
  economicOptimismIncome,
  percentageComparisonBlocks,
  cityRanking,
  infrastructureMap,
  movingMap,
  housingWalkabilityScatterplot,
  percentageBlocks,
  nestedBlocks,
  flowChart,
  barGraph,
  radialGraphs,
  transition,
  textBox,
  macroeconomicOptimism,
  percentageComparisonCards,
  dotMatrix,
  stickyHeader,
  columns,
  footer,
  allkeyfindings,
  footnote
}
