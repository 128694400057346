class ScrollLayoutModel {
  constructor(h, yOverflow) {
    this.h = h;
    this.yOverflow = yOverflow;

    this.l0 = {
      h: this.h * 2 + this.yOverflow, // add in first step y overflow (over 100vh)
      y: 0
    };

    this.l1 = {
      h: this.h * 1.2,
      y: this._cY(this.l0)
    };

    this.l2 = {
      h: this.h * 1,
      y: this._cY(this.l1)
    };

    this.l3 = {
      h: this.h * 1.2,
      y: this._cY(this.l2)
    };

    this.l4 = {
      h: Math.round(5 * (this.h / 3)),
      y: this._cY(this.l3)
    };

    this.l5 = {
      h: this.h,
      y: this._cY(this.l4)
    };

    this.layouts = [this.l0, this.l1, this.l2, this.l3, this.l4, this.l5];
  }

  calculateProgress(pageOffset) {
    const step = this.stepForPageYOffset(pageOffset);
    const layout = this.layouts[step - 1];
    let progress = (pageOffset - layout.y) / layout.h || 0;
    if (progress > 1.0) {
      progress = 1.0;
    }

    return {
      step: step,
      progress: progress
    };
  }

  stepForPageYOffset(yOffset) {
    const count = this.layouts.length;

    if (yOffset <= 0) {
      return 1;
    }

    for (var i = 0; i < count - 1; i++) {
      let nextStepIndex = i + 1;
      // If our pageY offset is less than the next step's
      // yOffset return current index
      let nextL = this.layouts[nextStepIndex];
      if (yOffset < nextL.y) {
        return i + 1;
      }
    }

    // We're on the last step, return current index
    return count;
  }

  pageYOffsetForStep(step) {
    const l = this.layouts[step - 1];
    return l.y;
  }

  stepCount() {
    return this.layouts.length;
  }

  totalHeight() {
    return this.layouts.reduce((a, l) => a + l.h, 0);
  }

  // Utils
  _cY(prevL) {
    return prevL.y + prevL.h;
  }
}

export default ScrollLayoutModel;
