import React, { useState } from "react";
import { INDICES, indexQuestions, SCORES, indexcolors } from "../constants";
import { dataByIndex } from "./Grid";
import CityList from "./CityList";
import keys from "keys";
import AsyncComp from "AsyncComp";

const CityIndexMobile = ({ data, explainer }) => {
  const [index, setIndex] = useState(INDICES[0]);

  return (
    <div className={"CityIndexMobile"}>
      <IndexSelect selected={index} set={setIndex} />
      <div
        className="CityIndex-dimension-toggle-question"
        style={{ color: indexcolors[5] }}
      >
        {indexQuestions[index]}
      </div>
      <Results index={index} data={dataByIndex(data, index)} />
    </div>
  );
};

const IndexSelect = ({ selected, set }) => {
  return (
    <select
      className="Select"
      value={selected}
      onChange={e => set(e.target.value)}
    >
      {INDICES.map(index => (
        <option key={index} value={index}>
          {index}
        </option>
      ))}
    </select>
  );
};

const Results = ({ data, index }) => {
  const citiesByScore = SCORES.reduce((r, score) => {
    r[score] = data.reduce((r, datum) => {
      if (+datum[index] === score) {
        r.push(datum[keys.label]);
      }
      return r;
    }, []);
    return r;
  }, {});

  return (
    <div className={"CityIndexMobile-results"}>
      <div className={"CityIndexMobile-results-key"}>
        <div>Agreed More</div>
        <div>Agreed Less</div>
      </div>
      <div className={"ScoreCities"}>
        {SCORES.reverse().map(score => (
          <ScoreCitiesItem
            key={score}
            cities={citiesByScore[score]}
            fill={indexcolors[score]}
          />
        ))}
      </div>
    </div>
  );
};

const AsyncCityIndexMobile = props => (
  <AsyncComp>
    <CityIndexMobile {...props} />
  </AsyncComp>
);

export default AsyncCityIndexMobile;

const ScoreCitiesItem = ({ cities = [], fill }) => {
  const [open, set] = useState(false);
  return (
    <div className={"ScoreCities-item"}>
      <button
        className={"ScoreCities-item-button"}
        style={{
          backgroundColor: fill
        }}
        onClick={() => set(!open)}
      >
        {cities.length} cities
      </button>
      {open && <CityList items={cities} fill={fill} />}
    </div>
  );
};
