import React from "react";
import { Waypoint } from "react-waypoint";
import { a, useSpring, config } from "react-spring";
import "./DotComparison.scss";
import { duogreen as colors } from "../constants";

export default ({ dotMatrixBuilder }) => {
  return (
    <div
      className={`DotComparison ${
        dotMatrixBuilder.length > 1 ? `DotComparison--multiple` : ""
      }`}
    >
      {dotMatrixBuilder.map(
        ({ id, antecedant, consequent, ratioFormat, ratioLabel }) => (
          <Matrix
            key={id}
            antecedant={antecedant}
            consequent={consequent}
            format={ratioFormat}
            text={ratioLabel}
          />
        )
      )}
    </div>
  );
};

const Matrix = ({ antecedant, consequent, text, format }) => {
  antecedant = +antecedant;
  consequent = +consequent;

  const dim = 500;
  const cols = 10;
  const rows = Math.ceil(consequent / cols);
  const track = dim / cols;
  const r = dim / cols / 3;

  const [circle, setCircle] = useSpring(() => ({
    fill: colors.default,
    config: config.stiff
  }));

  function enter() {
    setCircle({
      fill: colors.filled,
      delay: 100
    });
  }

  const value =
    format === "percentage"
      ? `${antecedant}%`
      : format === "ratio"
      ? `${antecedant} of ${consequent}`
      : null;

  return (
    <Waypoint
      onEnter={() => {
        enter();
      }}
    >
      <div>
        <svg viewBox={`0 0 ${dim} ${track * rows}`}>
          {new Array(consequent).fill(null).map((d, i) => {
            const x = (i % cols) * track + track / 2;
            const y = Math.floor(i / cols) * track + track / 2;
            return (
              <a.circle
                key={i}
                r={r}
                cx={x}
                cy={y}
                style={i < antecedant ? circle : {}}
                fill={colors.default}
              />
            );
          })}
        </svg>
        <div className="DotMatrix-value" style={{ color: colors.filled }}>
          {value}
        </div>
        <div className="DotMatrix-label">{text}</div>
      </div>
    </Waypoint>
  );
};
