import React from "react";
import { useCsvStore } from "Fetch";
import { Block } from "../components/StackedBlock";
import useBreakpoint from "../components/useBreakpoint";
import BlockHeading from "../components/BlockHeading";
import "./QuoteGrid.scss";

export default ({ heading, csv }) => {
  const data = useCsvStore(csv[0].url);
  const breakpoint = useBreakpoint();

  if (!data) {
    return null;
  }

  const style =
    breakpoint === "desktop"
      ? {
          margin: "0.5rem",
          flexWrap: "nowrap"
        }
      : {
          margin: "1rem",
          flexWrap: "wrap"
        };

  return (
    <section className="">
      <BlockHeading text={heading} />
      <ul className="Flex-list" style={style}>
        {data
          ? Object.keys(data[0]).map((d, i) => {
              return d !== "Total" ? (
                <li key={`${i}_${d}`} className="StackedBlocks-item">
                  <Block
                    title={d}
                    count={parseInt(data[0][d])}
                    index={i}
                    total={parseInt(data[0]["Total"])}
                  />
                </li>
              ) : null;
            })
          : null}
      </ul>
    </section>
  );
};
