import React from "react";
import Text from "../blocks/Text";
import Footnote from "./Footnote";
import "../blocks/TextBlock.scss";

export default ({ heading, text, footnote }) => {
  return (
    <section className="Transition TextBlock">
      <div className="TextBlock-wrapper TextBlock-single">
        <h2 className="TextBlock-header">{heading}</h2>
        <div className="TextBlock-text">
          <Text text={text} />
        </div>
      </div>
      {footnote ? <Footnote footnote={footnote} /> : null}
    </section>
  );
};
