import React from "react";
import { Link } from "react-router-dom";
import "./Missing.scss";

const Missing = () => {
  return (
    <div className="Missing">
      <p>That page doesn't exist. </p>
      <p>
        Go <Link to="/">Home</Link>
      </p>
    </div>
  );
};

export default Missing;
