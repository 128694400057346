import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useSpring, useTransition, a, config } from "react-spring";
import MenuIconSVG from "svg/menu-icon.svg";
import { ReactComponent as ShareIconSVG } from "svg/share.svg";
import { ReactComponent as ShareIconActiveSVG } from "svg/share-active.svg";
import X from "svg/x.svg";
import { useApi } from "Fetch";
import { ReactComponent as DownloadIconSVG } from "svg/download.svg";
import {
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton,
  FacebookIcon,
  EmailIcon,
  TwitterIcon
} from "react-share";
import "./Nav.scss";

const query = `
  query {
    globalSets(handle: "nav") {
      ...on nav_GlobalSet {
        download {
          url
        }
        navBuilder {
          ...on navBuilder_anchor_BlockType {
            id
            typeHandle
            heading
            linkUri
          }
          ...on navBuilder_subnav_BlockType {
            id
            typeHandle
            children {
              ...on navBuilder_subnavSection_BlockType {
                id
                typeHandle
                heading
                keyFindings {
                  ...on keyFindings_keyFinding_Entry {
                    id
                    navTitle
                    title
                    slug
                  }
                }
              }
            }
          }
          ...on navBuilder_page_BlockType {
            id
            typeHandle
            entry {
              title
              uri
            }
          }
        }
      }
    }
  }
`;

export default () => {
  const res = useApi(query);
  const [open, setOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const [props, set] = useSpring(() => ({
    transform: "translateX(-100%)"
  }));

  if (!res) {
    return null;
  }

  const data = res.data.globalSets[0].navBuilder;
  const downloadUrl = res.data.globalSets[0].download[0].url;

  const blocks = {
    anchor: Anchor,
    subnav: Subnav,
    page: Page
  };

  set({
    transform: `translateX(${open ? 0 : -100}%)`
  });

  const close = {
    onClick: () => setOpen(false)
  };

  return (
    <>
      <button className="Nav-toggle menu-button" onClick={() => setOpen(!open)}>
        <img src={MenuIconSVG} alt="main menu" />
      </button>
      <a.nav style={props} className="Nav">
        <div>
          <button className="menu-button" {...close}>
            <img src={X} alt="close menu" />
          </button>
          <div className="Nav-menu">
            {data.map(d => {
              const Comp = blocks[d.typeHandle];
              return <Comp {...d} key={d.id} close={close} />;
            })}
          </div>
        </div>
      </a.nav>
      <div className="Nav-buttons">
        <a
          className="DownloadPaper menu-button"
          href={downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIconSVG />
        </a>
        <div className="Share">
          <button
            className={`Share-toggle menu-button`}
            onClick={() => setShareOpen(!shareOpen)}
          >
            {shareOpen ? <ShareIconActiveSVG /> : <ShareIconSVG />}
          </button>
          <Share isOpen={shareOpen} />
        </div>
      </div>
    </>
  );
};

const Anchor = ({ heading, linkUri, close }) => {
  return (
    <HashLink title={heading} to={linkUri} {...close}>
      {heading}
    </HashLink>
  );
};

const Page = ({ entry, close }) => {
  return (
    <Link to={`/${entry[0].uri}`} {...close}>
      {entry[0].title}
    </Link>
  );
};

const Subnav = ({ close, children }) => {
  return (
    <div className="Nav-menu-subnav">
      {children.map(
        ({ id, heading, keyFindings }) =>
          keyFindings.length > 0 && (
            <div key={id} className="Nav-menu-subnav-group">
              <div className="Nav-menu-subnav-group-heading">
                <a href={`/#${keyFindings[0].slug}`} {...close}>
                  {heading}
                </a>
              </div>
              {keyFindings.map(({ id, navTitle, slug }) => (
                <a key={id} href={`/#${slug}`} {...close}>
                  {navTitle}
                </a>
              ))}
            </div>
          )
      )}
    </div>
  );
};

const Share = ({ isOpen }) => {
  const SIZE = 50;
  const items = isOpen
    ? [
        <FacebookShareButton key="fb" url={window.location.href}>
          <FacebookIcon size={SIZE} iconBgStyle={{ fill: "none" }} />
        </FacebookShareButton>,
        <TwitterShareButton key="tw" url={window.location.href}>
          <TwitterIcon size={SIZE} iconBgStyle={{ fill: "none" }} />
        </TwitterShareButton>,
        <EmailShareButton key="em" url={window.location.href}>
          <EmailIcon size={SIZE} iconBgStyle={{ fill: "none" }} />
        </EmailShareButton>
      ]
    : [];
  const trail = useTransition(items, d => d.key, {
    from: { opacity: 0, transform: `translateY(-50%)`, pointerEvents: "none" },
    enter: { opacity: 1, transform: `translateY(0%)`, pointerEvents: "auto" },
    leave: { opacity: 0, transform: `translateY(-50%)`, pointerEvents: "none" },
    config: config.stiff,
    trail: 200,
    unique: true
  });
  return (
    <a.div className={`Share-buttons`}>
      {trail.map(({ item, key, props }) => {
        return (
          <a.div style={props} key={key}>
            {item}
          </a.div>
        );
      })}
    </a.div>
  );
};
