import { useReducer, useLayoutEffect, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";

const useScrollTrigger = () => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "update":
          return {
            ...state,
            items: {
              ...state.items,
              ...action.value
            }
          };

        case "setCurrent":
          return {
            ...state,
            currentItem: action.value
          };

        default:
          throw new Error(`Unknown action type: ${action.type}`);
      }
    },
    {
      currentItem: null,
      items: {}
    }
  );

  const update = value => {
    dispatch({
      type: "update",
      value
    });
  };

  const useResizeBounds = () => {
    const items = useRef([]);
    const observer = useRef(
      new ResizeObserver(() => {
        const updates = {};
        items.current.forEach(entry => {
          const { top, height } = entry.getBoundingClientRect();
          const { scrollY } = window;
          updates[entry.id] = {
            top: Math.round(top) + scrollY,
            bottom: Math.round(top + height) + scrollY
          };
        });
        update(updates);
      })
    );
    const addItem = elem => {
      items.current.push(elem);
      observer.current.observe(elem);
    };

    return addItem;
  };

  useLayoutEffect(() => {
    const triggerHook = 0.5;
    if (state.items) {
      const onScroll = () => {
        const scrollY = window.scrollY + window.innerHeight * triggerHook;
        const currentIndex = Object.keys(state.items).reduce((r, key) => {
          if (!state.items[key]) return r;
          const { top, bottom } = state.items[key];
          if (scrollY >= top && scrollY < bottom) {
            return key;
          }
          return r;
        }, null);

        if (currentIndex !== state.currentItem) {
          dispatch({
            type: "setCurrent",
            value: currentIndex
          });
        }
      };
      onScroll();
      window.addEventListener("scroll", onScroll);
      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [state.items, state.currentItem]);

  const addItem = useResizeBounds();

  return {
    currentItem: state.currentItem,
    measurements: state.items,
    addItem
  };
};

export default useScrollTrigger;
