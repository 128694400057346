import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSpring, useSprings, config } from "react-spring";
import keys from "keys";
import { indexcolors as colors } from "../constants";
import { INDICES as indices } from "../constants";
import orderBy from "lodash/orderBy";
import { useIndexLayouts } from "Fetch";
import AsyncComp from "AsyncComp";
import { Waypoint } from "react-waypoint";
import { encodeSlug } from "helpers";

const dpr = typeof window !== "undefined" ? window.devicePixelRatio : 1;

const Grid = ({ data, currentIndex, zoomIndex, reverseScale = false }) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  });

  const allLayouts = useIndexLayouts(dimensions, reverseScale);

  const gridItems =
    allLayouts && dimensions.width > 0
      ? allLayouts[`${currentIndex}${zoomIndex}`]
      : null;

  const containerRef = useRef();
  useEffect(() => {
    let timer;

    const set = () => {
      setDimensions(containerRef.current.getBoundingClientRect());
    };

    const onResize = () => {
      clearTimeout(timer);
      timer = setTimeout(set, 100);
    };

    set();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="Grid" ref={containerRef} style={{ position: "relative" }}>
      {gridItems && (
        <Canvas
          raw={data}
          items={gridItems}
          canvasWidth={Math.floor(dimensions.width)}
          canvasHeight={Math.floor(dimensions.height)}
          keyPad={currentIndex === "all"}
        />
      )}
    </div>
  );
};

const AsyncGrid = props => {
  return (
    <AsyncComp onSetActive={props.onSetActive}>
      <Grid {...props} />
    </AsyncComp>
  );
};

export default AsyncGrid;

const Canvas = ({ items, raw, canvasWidth, canvasHeight, keyPad }) => {
  const hovered = useRef();
  const ttvisible = useRef(false);
  const history = useHistory();

  const [springs, set] = useSprings(items.length, index => {
    const { x, y, width, height, fill, textOpacity } = items[index];
    return {
      from: {
        x,
        y,
        width,
        height,
        fill,
        textOpacity,
        opacity: 1
      },
      config: config.stiff
      // delay: index
    };
  });
  const [animate, setAnimate] = useState(false);

  const [ttProps, setTtprops] = useSpring(() => ({
    opacity: 0
  }));

  useEffect(() => {
    set(index => {
      const {
        x,
        y,
        width,
        height,
        textOpacity,
        label,
        fill,
        fill_tint
      } = items[index];
      return {
        x,
        y,
        width,
        height,
        fill: !hovered.current || hovered.current === label ? fill : fill_tint,
        textOpacity,
        config: config.stiff
        // delay: index
      };
    });
  }, [items, set]);

  const ref = useRef();
  const mouse = useRef();

  const getHoveredItem = useCallback(
    (mx, my) => {
      for (let i = 0; i < items.length; i++) {
        const { x, y, width, height } = items[i];
        if (mx >= x && mx < x + width && my >= y && my < y + height) {
          return items[i];
        }
      }
      return null;
    },
    [items]
  );

  useEffect(() => {
    if (ref.current) {
      const setFromEvent = e => {
        const { x, y, width, height } = ref.current.getBoundingClientRect();

        //mouse coords relative to canvas
        const mx = e.clientX - x;
        const my = e.clientY - y;

        mouse.current = {
          x: mx,
          y: my
        };

        let visible = false;
        if (mx >= 0 && mx < width && my >= 0 && my < height) {
          //check if mouse is inside canvas
          const hoveredItem = getHoveredItem(mx, my);
          hovered.current = hoveredItem;
          if (hoveredItem) {
            visible = true;
          }
        }
        ttvisible.current = visible;
        setTtprops({
          opacity: visible ? 1 : 0
        });
      };

      const onScroll = () => {
        setTtprops({
          opacity: 0
        });
      };
      window.addEventListener("mousemove", setFromEvent);
      window.addEventListener("scroll", onScroll);

      return () => {
        window.removeEventListener("mousemove", setFromEvent);
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [items, setTtprops, getHoveredItem]);

  useEffect(() => {
    if (ref.current && animate) {
      const width = canvasWidth;
      const height = canvasHeight;
      const fontSize = height < 600 && !keyPad ? 12 : 14;
      const tooltipFontsize = fontSize * 1.2;
      const lineHeight = 2.5;
      const ttwidth = 250;
      const ttheight = (indices.length + 2) * fontSize * lineHeight;
      const keywidth = 50;
      const keyheight = height < 600 && !keyPad ? 20 : 25;
      const keyx = width - 5 * keywidth;
      const keystart = "Agreed Less";
      const keyend = "Agreed More";
      const textOffsetY = keyPad ? 5 : 0;
      const textOffsetX = keyPad ? 2.5 : 2;

      const c = ref.current.getContext("2d", { alpha: true });
      c.setTransform(dpr, 0, 0, dpr, 0, 0);

      let raf;

      const update = () => {
        c.clearRect(0, 0, width, height);

        c.font = `${fontSize}px ApercuWeWorkMono-Regular`;

        //Key
        [1, 2, 3, 4, 5].forEach((grade, i) => {
          c.fillStyle = colors[grade];
          c.fillRect(keyx + keywidth * i, 0, keywidth, keyheight);
          c.fillStyle = "#000";
          c.fillText(
            i + 1,
            keyx - keywidth / textOffsetX + keywidth * (i + 1),
            keyheight / 2 + textOffsetY
          );
        });

        c.fillStyle = "#000";
        c.textAlign = "start";
        c.fillText(keystart, keyx, keyheight + (9 + textOffsetY));
        c.textAlign = "end";
        c.fillText(keyend, width, keyheight + (9 + textOffsetY));

        c.font = `500 ${fontSize}px ApercuPro`;
        c.fillText("KEY", keyx - fontSize, keyheight - 10);

        c.font = `${fontSize}px ApercuWeWorkMono-Regular`;

        //Tiles
        springs.forEach(({ x, y, width, height, fill: afill }, i) => {
          const { label, isCurrent, fill, fill_tint } = items[i];
          c.beginPath();
          c.fillStyle =
            ttvisible.current === false || hovered.current.label === label
              ? fill
              : fill_tint;
          c.rect(x.value, y.value, width.value, height.value);
          c.fill();
          c.lineWidth = 1;
          c.strokeStyle = "#ffffff";
          c.stroke();

          if (isCurrent) {
            c.textAlign = "center";
            c.textBaseline = "middle";
            c.fillStyle = "#000000";

            c.fillText(
              label,
              x.value + width.value / 2,
              y.value + height.value / 2
            );
          }
        });

        //Tooltip
        if (hovered.current) {
          let { x, y } = mouse.current;
          y -= ttheight / 2;
          if (x + ttwidth > width) {
            x -= ttwidth;
          }
          if (y < 2) {
            y = 2;
          } else if (y + ttheight > height) {
            y = height - ttheight - 2;
          }

          const opacity = ttProps.opacity.value;
          c.save();
          c.globalAlpha = opacity;
          c.fillStyle = "#ffffff";
          c.strokeStyle = colors[5];
          c.rect(x, y, ttwidth, ttheight);
          c.stroke();
          c.fill();
          c.fillStyle = "#000000";
          c.textAlign = "center";
          c.textBaseline = "middle";
          c.fillText(hovered.current.label, x + ttwidth / 2, y + fontSize * 2);

          indices.forEach((index, i) => {
            const Y = y + fontSize * (i + 2) * lineHeight;
            const value = raw.find(
              d => d[keys.label] === hovered.current.label
            )[index];
            c.textAlign = "start";
            c.textBaseline = "middle";
            c.fillStyle = colors[value];
            c.fillRect(
              x + tooltipFontsize,
              Y - lineHeight * 0.15 * fontSize,
              tooltipFontsize,
              fontSize * (lineHeight * 0.85)
            );
            c.fillStyle = "#000000";
            c.fillText(value, x + tooltipFontsize * 1.25, Y + fontSize * 0.85);
            c.fillText(index, x + tooltipFontsize * 2.5, Y + fontSize * 0.85);
          });
          c.restore();
        }
        raf = requestAnimationFrame(update);
      };
      raf = requestAnimationFrame(update);

      return () => {
        cancelAnimationFrame(raf);
      };
    }
  }, [
    animate,
    springs,
    raw,
    items,
    ttProps,
    canvasWidth,
    canvasHeight,
    keyPad
  ]);

  return (
    <Waypoint
      onEnter={() => {
        setAnimate(true);
      }}
      onLeave={() => {
        setAnimate(false);
      }}
    >
      <canvas
        onClick={() => {
          const item = getHoveredItem(mouse.current.x, mouse.current.y);
          history.push(`/city/${encodeSlug(item.label)}`);
        }}
        width={canvasWidth * dpr}
        height={canvasHeight * dpr}
        ref={ref}
      />
    </Waypoint>
  );
};

export const dataByIndex = (data, currentIndex, indexOrder) => {
  const result = data.map((d, i) => ({
    ...d,
    ...indices.map(ind => ({ [ind]: +d[ind] })),
    itemKey: `${currentIndex}-${d[keys.label]}`,
    index: currentIndex,
    value: +d[currentIndex]
  }));
  return sortAlphaScore(result, currentIndex, indexOrder);
};

const sortAlphaScore = (data, index = "value", indexOrder = "asc") => {
  return orderBy(
    data,
    [index, keys.label],
    [indexOrder, "asc"]
  ).map((d, i) => ({ ...d, indexRank: i }));
};
