import React from "react";

import { Spring, config } from "react-spring/renderprops.cjs";

import remap from "../utils/remap";
import { stepProgress } from "../utils/step";

import "../styles.scss";

const WavePath = React.forwardRef(({ path, color }, ref) => {
  return <path ref={ref} d={path} stroke={"none"} fill={color} />;
});

function buildPath({ points, strokeWidth, index }) {
  const offsetY = strokeWidth * index;
  return (
    "M" +
    points
      .map(p => {
        return p[0] + "," + (p[1] + offsetY - strokeWidth / 2);
      })
      .join(" L") +
    "L" +
    points
      .slice(0)
      .reverse()
      .map(p => {
        return p[0] + "," + (p[1] + offsetY + strokeWidth / 2);
      })
      .join(" L") +
    "Z"
  );
}

const totalVerts = 400;
const waveHeight = 20;

const VERTS = [];
for (var i = 0; i <= totalVerts; i++) {
  VERTS.push(i);
}

export default function AWaveBackground({ step, progress, height }) {
  const step3Progress = stepProgress(3, step, progress);
  const step4Progress = stepProgress(4, step, progress);
  const step5Progress = stepProgress(5, step, progress);
  const step6Progress = stepProgress(6, step, progress);
  const longPs = step3Progress + step4Progress + step5Progress + step6Progress;
  const aStageOffset = height - remap(longPs, [0.3, 3], [0, height * 2]);

  return (
    <Spring
      from={{ aprogress: longPs, aStageOffset: aStageOffset }}
      to={{ aprogress: longPs, aStageOffset: aStageOffset }}
      config={config.molasses}
    >
      {props => (
        <WaveBackground
          progress={props.aprogress}
          height={height}
          aStageOffset={aStageOffset}
        />
      )}
    </Spring>
  );
}
export function WaveBackground({ progress, height, aStageOffset }) {
  const path1Ref = React.useRef(null);
  const path2Ref = React.useRef(null);
  const path3Ref = React.useRef(null);
  const path4Ref = React.useRef(null);
  const strokeWidth = Math.round(
    remap(progress, [0.8, 2], [Math.floor(height / 12), Math.floor(height / 4)])
  );
  const t = Math.round(remap(progress, [0.5, 2], [0, 1350]));
  const points = VERTS.map(x => {
    const y =
      (strokeWidth / 2 - waveHeight) * -1 +
      waveHeight * Math.sin((x + t) / 100);
    return [x, y];
  });
  const path1 = buildPath({ points, strokeWidth, index: 1 });
  const path2 = buildPath({ points, strokeWidth, index: 2 });
  const path3 = buildPath({ points, strokeWidth, index: 3 });
  const path4 = buildPath({ points, strokeWidth, index: 4 });

  return (
    <div
      className="wave-container"
      style={{
        height: `${height}px`,
        transform: `translateY(${aStageOffset}px)`
      }}
    >
      <svg
        viewBox={`0 0 ${totalVerts} ${height + waveHeight * 2}`}
        preserveAspectRatio="none"
        style={{
          height: `${height}px`
        }}
      >
        <WavePath path={path1} ref={path1Ref} color="#005056" />
        <WavePath path={path2} ref={path2Ref} color="#00A18D" />
        <WavePath path={path3} ref={path3Ref} color="#25BDA3" />
        <WavePath path={path4} ref={path4Ref} color="#94DDB7" />
      </svg>
    </div>
  );
}
