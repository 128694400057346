import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./GlobalHeader.scss";

export const GlobalHeader = () => {
  const onClick =
    window.location.pathname === "/"
      ? () => {
          window.scrollTo(0, 0);
        }
      : null;
  const subtitle = useSelector(state => state.navigation.subtitle);

  return (
    <div className="GlobalHeader">
      <header className="GlobalHeader-inner">
        <Link to="/" title="Go to homepage" onClick={onClick}>
          {subtitle && `${subtitle} | `}The Future of Work and Cities
        </Link>
      </header>
    </div>
  );
};
