import React from "react"
import "./Columns.scss";

export default ({ children }) => {
  return <div className="Columns">
    {children.map(({ id, heading, text }) => <div key={id} className="Column">
      {heading && <div className="Columns-label">{heading}</div>}
      {text && <div className="Columns-text" dangerouslySetInnerHTML={{ __html: text }} />}
    </div>)}
  </div>
}
