import React, { useReducer } from "react";
import "./CityIndex.scss";
import Grid from "components/Grid";
import { INDICES as indices, indexQuestions } from "../constants";
import ReactTooltip from "react-tooltip";
import CityIndexMobile from "./CityIndexMobile";
import useBreakpoint from "components/useBreakpoint";
import { useIndex } from "Fetch";
import CityIndexScroller from "./CityIndexScroller";
import Loading from "./Loading";
export { CityIndexScroller };

const reducer = (state, action) => {
  switch (action.type) {
    case "current":
      return {
        ...state,
        lastIndex: state.currentIndex,
        currentIndex: action.value,
        zoomIndex:
          action.value === "all"
            ? "all"
            : state.zoomIndex !== "all"
            ? state.zoomIndex
            : action.value
      };

    default:
      throw new Error(`unknown action type: ${action.type}`);
  }
};

const CityIndexControls = ({ dispatch, currentIndex }) => {
  return (
    <div className="CityIndex-controls">
      <button
        className="CityIndex-dimension-toggle"
        style={{ fontWeight: "700" }}
        onClick={() => {
          dispatch({
            type: "current",
            value: "all"
          });
        }}
      >
        Overview
      </button>
      {indices.map(d => {
        return (
          <Dimension
            key={d}
            value={d}
            set={value => {
              dispatch({
                type: "current",
                value: value
              });
            }}
            selected={currentIndex === d}
          />
        );
      })}
    </div>
  );
};

export const CityIndexInteractive = ({ explainer }) => {
  return (
    <div id="CityIndex" className={"CityIndexInteractive"}>
      <CityIndex explainer={explainer} />
    </div>
  );
};

const CityIndex = ({ explainer }) => {
  const data = useIndex();
  const breakpoint = useBreakpoint();
  if (!data) {
    return <Loading />;
  }
  return breakpoint === "desktop" ? (
    <CityIndexDesktop data={data} explainer={explainer} />
  ) : (
    <CityIndexMobile data={data} explainer={explainer} />
  );
};

export default CityIndex;

const CityIndexDesktop = ({ data, initialIndex = "all", explainer }) => {
  const [state, dispatch] = useReducer(reducer, {
    lastIndex: initialIndex,
    zoomIndex: initialIndex,
    currentIndex: initialIndex
  });

  const { currentIndex, zoomIndex } = state;

  return (
    <div className="CityIndex">
      <CityIndexControls
        dispatch={dispatch}
        currentIndex={state.currentIndex}
      />
      <Grid data={data} currentIndex={currentIndex} zoomIndex={zoomIndex} />
      <ReactTooltip />
    </div>
  );
};

const Dimension = ({ value, set, selected }) => {
  return (
    <div>
      <button
        className="CityIndex-dimension-toggle"
        onClick={() => {
          set(selected ? "all" : value);
        }}
      >
        <div>{value}</div>
        {selected && (
          <div className="CityIndex-dimension-toggle-question">
            {indexQuestions[value]}
          </div>
        )}
      </button>
    </div>
  );
};
