import React, { useState, useEffect } from "react";
import { about as aboutQuery } from "queries";
import Builder from "./Builder";
import Loading from "./Loading";
import GoBack from "./GoBack";

export default ({ match }) => {
  const { loading, error, data } = useData(aboutQuery);

  useEffect(() => {
    fetch(
      `/actions/seomatic/meta-container/all-meta-containers/?uri=about&asArray=true`
    )
      .then(res => res.json())
      .then(res => {
        console.log(res);
        document.title = res.MetaTitleContainer.title.title;
      })
      .catch(err => {
        console.log(err);
      });
  }, [match]);

  return loading ? (
    <Loading />
  ) : error ? (
    <Error />
  ) : (
    <article
      className={"About"}
      style={{ backgroundColor: "#F8F1EB", paddingBottom: "5em" }}
    >
      <Builder blocks={data.entries[0].pageBuilder} />
      <div style={{ marginTop: "5em" }}>
        <GoBack />
      </div>
    </article>
  );
};

const Error = () => {
  return "Error";
};

const useData = query => {
  const [data, set] = useState();
  useEffect(() => {
    fetch(`/api`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query
      })
    })
      .then(res => res.json())
      .then(res => {
        set(res);
      });
  }, [query]);

  if (!data) {
    return { loading: true, error: false, data: {} };
  }
  if (data.errors) {
    console.log(data.errors);
    return { loading: false, error: true, data: {} };
  }

  return { loading: false, error: false, data: data.data };
};
