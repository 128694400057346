import React from "react";

import { ReactComponent as WeWork } from "svg/WeWorkLogo.svg";
import { ReactComponent as Aspen } from "svg/AspenLogo.svg";
import { ReactComponent as X } from "svg/XLogo.svg";

import "./FOWCLogo.scss";

export default () => (
  <div className="wework-fow-logo">
    <a href="https://wework.com" target="_blank" rel="noopener noreferrer">
      <WeWork />
    </a>
    <X />
    <a
      href="https://www.aspeninstitute.org/programs/future-of-work/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Aspen />
    </a>
  </div>
);
