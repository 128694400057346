import React from "react"
import { Waypoint } from "react-waypoint"
import { a, useSpring, config } from "react-spring"
import ChartKey from "components/ChartKey"
import { greens } from "../constants"

import "./RadialGraph.scss"

const HEIGHT=300
const WIDTH=300
const STROKE_WIDTH=15

const data = [
 {
   chartData: {
     "Sole Proprietor / Freelancer": 88,
     "2-10 Person Business": 90,
     "11-250 Person Business": 88,
     "250-999 Person Business": 84,
     "1,000+ Person Business": 86
   },
   avg: 87,
   heading: "Would you hire someone who doesn't have a college degree but has the right skills, training, or experience for the job developed at a coding, design, or business bootcamp?",
 },
 {
   chartData: {
     "Sole Proprietor / Freelancer": 67,
     "2-10 Person Business": 75,
     "11-250 Person Business": 81,
     "250-999 Person Business": 85,
     "1,000+ Person Business": 81
   },
   avg: 79,
   heading: "Would you consider taking on apprentices that would lead to a job interview for a full time role?",
 },
 {
   chartData: {
     "Sole Proprietor / Freelancer": 63,
     "2-10 Person Business": 75,
     "11-250 Person Business": 81,
     "250-999 Person Business": 84,
     "1,000+ Person Business": 82
   },
   avg: 77,
   heading: "Would you consider contributing to a career and skills training savings account for your employees if the government provided you 25% tax credits for your contributions?",
 }
]
  // {
  //   footnotes: [
  //     "The Flatiron School, General Assembly, and Lambda School",
  //     "The Aspen Institute Future of Work Initiative, Apprenticeships as a Model of Skills-Based Training for Jobs of the Future",
  //     "SkillsFuture Singapore, 2018 Year in Review"
  //   ]
  // }

export default () => {
  return <section className="RadialGraphs">
    <ChartKey data={Object.keys(data[0].chartData)} colors={greens} className="RadialGraph-ChartKey" offset={2} />
    <div className="Flex-list" style={{ flexWrap: 'wrap' }}>
      {data.map((dat,i) => {
        const d = dat.chartData
        const values = Object.values(d)
        // const avg = values.reduce((r,d) => (r + +d), 0)/values.length

        return <div key={dat.heading} className="RadialGraph">
          <p className="RadialGraph-copy">{dat.heading}</p>
          <svg
            className="RadialGraph-graph ff-regular"
            viewBox={`0 0 ${WIDTH} ${HEIGHT}`} preserveAspectRatio={'xMidYMid meet'}>
            {values.map((value,i) => {
              return <Ring
                key={`${value}_${i}`}
                xVal={WIDTH/2}
                yVal={HEIGHT/2}
                circleLength={Math.floor(value)}
                radius={(HEIGHT/2)-(STROKE_WIDTH*(i+1))}
                index={i+1}
                color={greens[i+2]}
              />
            })}
            <text
              key={`${data.id}`}
              x={HEIGHT/2}
              y={HEIGHT/2}
              dy={12}
              dx={-24}
              fontSize={24}
              >{`${dat.avg}%`}</text>
          </svg>
        </div>
      })}
    </div>
  </section>
}

const Ring = ({xVal, yVal, radius, circleLength, index, color}) => {
  const dashArray = (Math.PI*2) * radius
  const offset = dashArray * (1 - (circleLength/100))

  const [style,setStyle] = useSpring(() => ({
    strokeDashoffset: `${dashArray}`,
    transform: 'rotate(-90deg) scaleY(-1)',
    transformOrigin: 'center',
    config: config.stiff
  }))

  function enter() {
    setStyle({
      strokeDashoffset: `${offset}`,
      transform: 'rotate(-90deg) scaleY(-1)',
      transformOrigin: 'center',
      delay: 50*index
    })
  }

  return <Waypoint onEnter={() => { enter() }}>
    <g>
      <text x={WIDTH/2} y={STROKE_WIDTH*index} fontSize={8} dy={4} dx={4}>{`${circleLength}%`}</text>
      <a.circle
        cx={xVal}
        cy={yVal}
        r={radius}
        stroke={color}
        fill={'none'}
        strokeWidth={STROKE_WIDTH}
        style={style}
        strokeDasharray={dashArray}
      />
    </g>
  </Waypoint>
}
