import React from "react";
import Blocks from "blocks";

const Builder = ({ blocks, useResizeBounds }) => {
  return blocks.map((block, i) => {
    const Block = Blocks[block.typeHandle];

    return Block ? (
      <Block key={block.id} {...block} useResizeBounds={useResizeBounds} />
    ) : (
      <pre key={`error-${i}`}>{`Unknown block type ${block.typeHandle}`}</pre>
    );
  });
};

export default Builder;
