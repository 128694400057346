export const keySet = (data, key) => {
  return Array.from(
    data.reduce((r, d) => {
      r.add(d[key]);
      return r;
    }, new Set())
  );
};

export const encodeSlug = name => name.toLowerCase().replace(" ", "-");

export const decodeSlug = name =>
  name
    .split("-")
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(" ");
